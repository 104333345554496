import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import ViewColumns          from "Components/Utils/View/ViewColumns";
import ViewItem             from "Components/Utils/View/ViewItem";



// Styles
const Container = Styled(ViewColumns)`
    grid-area: logistic;
`;



/**
 * The Order Logistic
 * @returns {React.ReactElement}
 */
function OrderLogistic() {
    const { elem } = Store.useState("order");


    // Do the Render
    return <Container message="ORDERS_LOGISTIC_INFO" amount="2">
        <ViewItem
            message="RETIREMENT_TYPES_SINGULAR"
            value={elem.retirementTypeName}
        />
        <ViewItem
            isHidden={!elem.logisticCode}
            message="LOGISTICS_SINGULAR"
            value={elem.logisticCode}
        />
        <ViewItem
            isHidden={!!elem.logisticAddress}
            message="ORDERS_RETIRE_ADDRESS"
            value={elem.subsidiaryLocation}
        />
        <ViewItem
            isHidden={!elem.logisticAddress}
            message="ORDERS_LOGISTIC_ADDRESS"
            value={elem.logisticAddress}
        />
        <ViewItem
            isHidden={!elem.logisticExternalID}
            message="ORDERS_LOGISTIC_EXTERNAL_ID"
            value={elem.logisticExternalID}
        />
    </Container>;
}

export default OrderLogistic;
