import React                from "react";
import Store                from "Dashboard/Core/Store";

// Dashboard
import BarIcon              from "Dashboard/Components/Core/BarIcon";



/**
 * The Catalog Menu
 * @returns {React.ReactElement}
 */
function CatalogMenu() {
    const { isCatalog } = Store.useState("auth");


    // Do the Render
    if (!isCatalog) {
        return <React.Fragment />;
    }
    return <>
        <BarIcon
            variant="accent"
            url="DASHBOARD"
            icon="home"
            message="DASHBOARD_NAME"
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            variant="accent"
            url="CATALOG"
            icon="product"
            message="PRODUCTS_NAME"
            withText
            withTooltip
            startsWith
        />
    </>;
}

export default CatalogMenu;
