import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { SubCategory }      from "Utils/API";



// The initial State
const initialState = {
    loading            : true,
    error              : false,
    edition            : 0,
    canCreate          : false,
    canEdit            : false,
    canFilter          : false,
    canImport          : false,
    canExport          : false,
    list               : [],
    total              : 0,
    elem               : {},
    macroCategories    : [],
    categories         : [],
    categoriesMacro    : {},
    categoriesPerMacro : {},
    filters            : {},
    hasFilters         : false,
    sort               : {
        orderBy  : "mainPosition",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "SUB_CATEGORY_LOADING" });
    },

    /**
     * Fetches the Sub Category List
     * @param {Function} dispatch
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        const data   = await SubCategory.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "SUB_CATEGORY_LIST", data });
    },

    /**
     * Fetches a single Sub Category
     * @param {Function} dispatch
     * @param {Number}   subCategoryID
     * @returns {Promise}
     */
    async fetchElem(dispatch, subCategoryID) {
        const data = await SubCategory.getOne({ subCategoryID });
        dispatch({ type : "SUB_CATEGORY_ELEM", data });
    },

    /**
     * Fetches the Sub Category Edit data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchEditData(dispatch) {
        const data = await SubCategory.getEditData();
        dispatch({ type : "SUB_CATEGORY_EDIT", data });
    },

    /**
     * Fetches the Sub Category Filter data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchFilterData(dispatch) {
        const data = await SubCategory.getFilterData();
        dispatch({ type : "SUB_CATEGORY_FILTER", data });
    },

    /**
     * Edits/Creates a Sub Category
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return SubCategory.edit(data);
    },

    /**
     * Deletes a Sub Category
     * @param {Function} dispatch
     * @param {Number}   subCategoryID
     * @returns {Promise}
     */
    deleteElem(dispatch, subCategoryID) {
        return SubCategory.delete({ subCategoryID });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "SUB_CATEGORY_LIST", "SUB_CATEGORY_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "SUB_CATEGORY_LOADING":
        return {
            ...state,
            loading            : true,
        };

    case "SUB_CATEGORY_LIST":
        return {
            ...state,
            loading            : false,
            error              : false,
            canCreate          : action.data.canCreate,
            canEdit            : action.data.canEdit,
            canFilter          : action.data.canFilter,
            canImport          : action.data.canImport,
            canExport          : action.data.canExport,
            list               : action.data.list,
            total              : action.data.total,
            filters            : action.data.filters,
            hasFilters         : !Utils.isEmpty(action.data.filters),
            sort               : action.data.sort,
        };

    case "SUB_CATEGORY_ELEM":
        return {
            ...state,
            error              : false,
            edition            : state.edition + 1,
            elem               : action.data.elem,
            macroCategories    : action.data.macroCategories,
            categoriesMacro    : action.data.categoriesMacro,
        };

    case "SUB_CATEGORY_EDIT":
        return {
            ...state,
            error              : false,
            edition            : state.edition + 1,
            macroCategories    : action.data.macroCategories,
            categoriesMacro    : action.data.categoriesMacro,
        };

    case "SUB_CATEGORY_FILTER":
        return {
            ...state,
            error              : false,
            edition            : state.edition + 1,
            macroCategories    : action.data.macroCategories,
            categories         : action.data.categories,
            categoriesPerMacro : action.data.categoriesPerMacro,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
