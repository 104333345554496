import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { EmailWhiteList }   from "Utils/API";



// The initial State
const initialState = {
    loading   : false,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canFilter : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    sort      : {
        orderBy  : "email",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "EMAIL_WHITE_LOADING" });
    },

    /**
     * Fetches the Email White List
     * @param {Function} dispatch
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, params = {}) {
        Navigate.unsetParams(params);
        const data = await EmailWhiteList.getAll(params);
        data.sort = params;
        dispatch({ type : "EMAIL_WHITE_LIST", data });
    },

    /**
     * Fetches a single Email from the White List
     * @param {Function} dispatch
     * @param {Number}   emailID
     * @returns {Promise}
     */
    async fetchElem(dispatch, emailID) {
        const data = await EmailWhiteList.getOne({ emailID });
        dispatch({ type : "EMAIL_WHITE_ELEM", data });
    },

    /**
     * Edits an Email in the White List
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editEmail(dispatch, data) {
        return EmailWhiteList.edit(data);
    },

    /**
     * Removes an Email from the White List
     * @param {Function} dispatch
     * @param {Number}   emailID
     * @returns {Promise}
     */
    removeEmail(dispatch, emailID) {
        return EmailWhiteList.remove({ emailID });
    },
};




/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.sendToText = Utils.combineValues(elem, "sendTo");
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "EMAIL_WHITE_LIST", "EMAIL_WHITE_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "EMAIL_WHITE_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "EMAIL_WHITE_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canFilter : action.data.canFilter,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
            sort      : action.data.sort,
        };

    case "EMAIL_WHITE_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : parseElem(action.data.elem),
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
