import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import ProcessSection       from "../Utils/ProcessSection";

// Dashboard
import Html                 from "Dashboard/Components/Common/Html";



// Styles
const Container = Styled(ProcessSection)`
    position: sticky;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: calc(var(--main-gap) / 2);
    background: white;
`;

const Total = Styled.div`
    display: flex;
    justify-content: space-between;
    b {
        color: var(--alternative-color);
    }
`;



/**
 * The Process View Totals
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProcessTotals(props) {
    const { subTotal, discount } = props;

    const { elem } = Store.useState("dashboardProcess");


    // Variables
    const logistic = elem.logisticPrice ?? 0;
    const total    = subTotal + logistic + discount;


    // Do the Render
    return <Container>
        <Total>
            <b>{NLS.get("GENERAL_SUBTOTAL")}</b>
            <Html content={Utils.formatPrice(subTotal)} />
        </Total>
        {!!logistic && <Total>
            <b>{NLS.get("ORDERS_LOGISTIC_PRICE")}</b>
            <Html content={Utils.formatPrice(logistic)} />
        </Total>}
        <Total>
            <b>{NLS.get("ORDERS_DISCOUNT")}</b>
            <Html content={Utils.formatPrice(discount)} />
        </Total>
        <Total>
            <b>{NLS.get("GENERAL_TOTAL")}</b>
            <Html content={Utils.formatPrice(total)} />
        </Total>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProcessTotals.propTypes = {
    subTotal : PropTypes.number.isRequired,
    discount : PropTypes.number.isRequired,
};

export default ProcessTotals;
