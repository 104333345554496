import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { DashboardMap }     from "Utils/API";



// The initial State
const initialState = {
    loading     : true,
    error       : false,
    list        : [],
    clients     : [],
    retirements : [],
    deliveries  : [],
    options     : {
        showSubsidiaries : true,
        subsidiaryRadius : 1000,
        showClients      : true,
        clientRadius     : 10,
        showRetirements  : true,
        retirementRadius : 10,
        showDeliveries   : true,
        deliveryRadius   : 10,
    },
    hasFilters  : false,
    filters     : {},
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "DASHBOARD_MAP_LOADING" });
    },

    /**
     * Fetches the Map Dashboard
     * @param {Function} dispatch
     * @param {Object=}  filters
     * @returns {Promise}
     */
    async fetchList(dispatch, filters = {}) {
        const data   = await DashboardMap.getAll(filters);
        data.filters = filters;
        dispatch({ type : "DASHBOARD_MAP_LIST", data });
    },

    /**
     * Sets an Option
     * @param {Function} dispatch
     * @param {String}   key
     * @param {*}        value
     * @returns {Void}
     */
    setOption(dispatch, key, value) {
        dispatch({ type : "DASHBOARD_MAP_OPTION", key, value });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.position = {
        lat : Number(elem.latitude),
        lng : Number(elem.longitude),
    };
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "DASHBOARD_MAP_LIST", "DASHBOARD_MAP_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "DASHBOARD_MAP_LOADING":
        return {
            ...state,
            loading     : true,
        };

    case "DASHBOARD_MAP_LIST":
        return {
            ...state,
            loading     : false,
            error       : false,
            list        : Utils.parseList(action.data.list, parseElem),
            clients     : Utils.parseList(action.data.clients, parseElem),
            retirements : Utils.parseList(action.data.retirements, parseElem),
            deliveries  : Utils.parseList(action.data.deliveries, parseElem),
            hasFilters  : !Utils.isEmpty(action.data.filters),
            filters     : action.data.filters,
        };

    case "DASHBOARD_MAP_OPTION":
        return {
            ...state,
            options : {
                ...state.options,
                [action.key] : action.value,
            },
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
