import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import OrderStateList       from "./OrderStateList";
import ViewSection          from "Components/Utils/View/ViewSection";



// Styles
const Container = Styled(ViewSection)`
    grid-area: states;
    max-height: 600px;
    overflow: auto;

    @media screen and (max-width: 600px) {
        max-height: none;
    }
`;



/**
 * The Order States
 * @returns {React.ReactElement}
 */
function OrderStates() {
    const { states } = Store.useState("order");


    // Do the Render
    if (!states.length) {
        return <React.Fragment />;
    }
    return <Container message="ORDERS_STATES_TRACKING">
        <OrderStateList states={states} />
    </Container>;
}

export default OrderStates;
