import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import ViewColumns          from "Components/Utils/View/ViewColumns";
import ViewItem             from "Components/Utils/View/ViewItem";

// Dashboard
import Html                 from "Dashboard/Components/Common/Html";



// Styles
const Container = Styled(ViewColumns)`
    grid-area: payment;
`;



/**
 * The Order Payment
 * @returns {React.ReactElement}
 */
function OrderPayment() {
    const { elem } = Store.useState("order");
    const { isFinanceAdmin } = Store.useState("auth");


    // Do the Render
    return <Container message="ORDERS_PAYMENT_INFO" amount="3">
        <ViewItem message="GENERAL_SUBTOTAL">
            <Html>{elem.grossTotalText}</Html>
        </ViewItem>
        <ViewItem message="ORDERS_DISCOUNT">
            <Html>{elem.discountTotalText}</Html>
        </ViewItem>
        <ViewItem message="GENERAL_TOTAL">
            <Html>{elem.netTotalText}</Html>
        </ViewItem>

        <ViewItem
            isHidden={!elem.logisticPrice}
            className={!elem.logisticFee ? "columns-double" : ""}
            message="ORDERS_LOGISTIC_PRICE"
        >
            <Html>{elem.logisticPriceText}</Html>
        </ViewItem>
        <ViewItem
            isHidden={!elem.logisticFee}
            message="ORDERS_LOGISTIC_FEE"
        >
            <Html>{elem.logisticFeeText}</Html>
        </ViewItem>
        <ViewItem
            isHidden={!elem.logisticPrice}
            message="ORDERS_TOTAL_TO_PAY"
        >
            <Html>{elem.totalToPayText}</Html>
        </ViewItem>

        <ViewItem
            isHidden={!isFinanceAdmin || !elem.commission}
            message="ORDERS_COMMISSION_RECEIVED"
        >
            <Html>{elem.commissionText}</Html>
        </ViewItem>
        <ViewItem
            isHidden={!isFinanceAdmin || !elem.benvidaFee}
            message="ORDERS_COMMISSION_BENVIDA"
        >
            <Html>{elem.benvidaFeeFull}</Html>
        </ViewItem>
        <ViewItem
            isHidden={!isFinanceAdmin || !elem.mercadoPagoFee}
            message="ORDERS_COMMISSION_MP"
        >
            <Html>{elem.mercadoPagoFull}</Html>
        </ViewItem>
    </Container>;
}

export default OrderPayment;
