import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import { ChangeProduct }    from "Utils/API";



// The initial State
const initialState = {
    loading               : true,
    error                 : false,
    edition               : 0,
    canCreate             : false,
    canEdit               : false,
    canValidate           : false,
    canFilter             : false,
    canImport             : false,
    canExport             : false,
    list                  : [],
    total                 : 0,
    elem                  : {},
    product               : {},
    macroCategories       : [],
    categoriesMacro       : {},
    subCategoriesCategory : {},
    laboratories          : [],
    saleTypes             : [],
    shapeTypes            : [],
    filters               : {
        search   : "",
        fromDate : "",
        toDate   : "",
    },
    sort                  : {
        filter   : "",
        orderBy  : "createdTime",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "CHANGE_PRODUCT_LOADING" });
    },

    /**
     * Fetches the Change Products
     * @param {Function} dispatch
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        const data = await ChangeProduct.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "CHANGE_PRODUCT_LIST", data });
    },

    /**
     * Fetches a single Change Product
     * @param {Function} dispatch
     * @param {Number}   changeProductID
     * @returns {Promise}
     */
    async fetchElem(dispatch, changeProductID) {
        const data = await ChangeProduct.getOne({ changeProductID });
        dispatch({ type : "CHANGE_PRODUCT_ELEM", data });
    },

    /**
     * Starts Using a Change Product
     * @param {Function} dispatch
     * @param {Number}   changeProductID
     * @returns {Promise}
     */
    startUsingElem(dispatch, changeProductID) {
        return ChangeProduct.startUsing({ changeProductID });
    },

    /**
     * Stops Using a Change Product
     * @param {Function} dispatch
     * @param {Number}   changeProductID
     * @returns {Promise}
     */
    stopUsingElem(dispatch, changeProductID) {
        return ChangeProduct.stopUsing({ changeProductID });
    },

    /**
     * Edits a Change Product
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return ChangeProduct.edit(data);
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate          = DateTime.formatDate(elem.createdTime,  "dashesSeconds");
    elem.processDate          = DateTime.formatDate(elem.processTime,  "dashesSeconds");
    elem.usingCircle          = elem.isUsing ? "red" : "green";
    elem.modificationTypeText = NLS.get("SELECT_MODIFICATION_TYPES", elem.modificationType);
    elem.potencyText          = `${elem.potency} ${elem.potencyTypeName}`;
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "CHANGE_PRODUCT_LIST", "CHANGE_PRODUCT_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "CHANGE_PRODUCT_LOADING":
        return {
            ...state,
            loading               : true,
        };

    case "CHANGE_PRODUCT_LIST":
        return {
            ...state,
            loading               : false,
            error                 : false,
            canCreate             : action.data.canCreate,
            canEdit               : action.data.canEdit,
            canValidate           : action.data.canValidate,
            canFilter             : action.data.canFilter,
            canImport             : action.data.canImport,
            canExport             : action.data.canExport,
            list                  : Utils.parseList(action.data.list, parseElem),
            total                 : action.data.total,
            filters               : action.data.filters,
            sort                  : action.data.sort,
        };

    case "CHANGE_PRODUCT_ELEM":
        return {
            ...state,
            error                 : false,
            edition               : state.edition + 1,
            elem                  : parseElem(action.data.elem),
            product               : Commons.parseProduct(action.data.product),
            macroCategories       : action.data.macroCategories,
            categoriesMacro       : action.data.categoriesMacro,
            subCategoriesCategory : action.data.subCategoriesCategory,
            saleTypes             : action.data.saleTypes,
            shapeTypes            : action.data.shapeTypes,
            potencyTypes          : action.data.potencyTypes,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
