// The App Actions
const actions = [
    {
        name    : "RESEND",
        icon    : "send",
    },
    {
        name    : "PRINT",
        icon    : "print",
        message : "GENERAL_PRINT",
    },


    // Module Actions
    {
        name : "SUBSIDIARY",
        icon : "subsidiary",
    },
    {
        name : "ORDER",
        icon : "order",
    },


    // Change Actions
    {
        name : "PROCESS",
        icon : "check",
    },
    {
        name : "VALIDATE",
        icon : "check",
    },
];

export default actions;
