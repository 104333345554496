import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Contact Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ContactEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { pharmacies, subsidiaries, subsidiariesPerPharmacy } = Store.useState("contact");
    const { editElem } = Store.useAction("contact");


    // The Initial Data
    const initialData = {
        contactID       : 0,
        pharmacyID      : [],
        subsidiaryID    : [],
        firstName       : "",
        lastName        : "",
        email           : "",
        sendOrderEmails : "",
    };

    // The Form Contact
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("contact", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("contact", open, elemID, null, setElem);

    // Returns a select of subsidiaries based on the pharmacies
    const getSubsidiaries = Utils.useSelectList(loading, data.pharmacyID, subsidiaries, subsidiariesPerPharmacy);


    // Do the Render
    const isEdit = Boolean(elemID);

    return <EditDialog
        open={open}
        icon="contact"
        title={isEdit ? "CONTACTS_EDIT_TITLE" : "CONTACTS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        noAutoFocus
    >
        <InputField
            type="chooser"
            name="pharmacyID"
            label="PHARMACIES_SINGULAR"
            options={pharmacies}
            value={data.pharmacyID}
            error={errors.pharmacyID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="subsidiaryID"
            label="SUBSIDIARIES_NAME"
            options={getSubsidiaries()}
            value={data.subsidiaryID}
            error={errors.subsidiaryID}
            onChange={handleChange}
        />

        <Columns>
            <InputField
                name="firstName"
                label="GENERAL_FIRST_NAME"
                value={data.firstName}
                error={errors.firstName}
                onChange={handleChange}
                isRequired
            />
            <InputField
                name="lastName"
                label="GENERAL_LAST_NAME"
                value={data.lastName}
                error={errors.lastName}
                onChange={handleChange}
                isRequired
            />

            <InputField
                type="email"
                name="email"
                label="GENERAL_EMAIL"
                value={data.email}
                error={errors.email}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="toggle"
                name="sendOrderEmails"
                label="CONTACTS_SEND_ORDER_EMAILS"
                value={data.sendOrderEmails}
                error={errors.sendOrderEmails}
                onChange={handleChange}
                withBorder
            />
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ContactEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default ContactEdit;
