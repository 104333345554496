import React                from "react";
import PropTypes            from "prop-types";



/**
 * The Map Info Window
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MapInfoWindow(props) {
    const { isOpen, map, position, name, image, onClose } = props;

    const [ info, setInfo ] = React.useState(null);


    // Create the Info Window
    React.useEffect(() => {
        if (map && !info) {
            const img     = image ? `<img src="${image}" alt="${name}" />` : "";
            const newInfo = new window.google.maps.InfoWindow({
                position,
                pixelOffset : new window.google.maps.Size(0, -25),
                maxWidth    : 200,
                content     : `<div class="map-info">${img}${name}</div>`,
            });
            window.google.maps.event.addListener(newInfo, "closeclick", () => {
                onClose();
            });
            setInfo(newInfo);
        }
    }, [ map, info ]);

    // Opens or closes the Info Window
    React.useEffect(() => {
        if (isOpen && info) {
            info.open(map);
        } else if (!isOpen && info) {
            info.close();
        }
    }, [ isOpen ]);


    // Do the Render
    return <React.Fragment />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MapInfoWindow.propTypes = {
    map      : PropTypes.object.isRequired,
    isOpen   : PropTypes.bool.isRequired,
    position : PropTypes.object.isRequired,
    name     : PropTypes.string.isRequired,
    image    : PropTypes.string.isRequired,
    onClose  : PropTypes.func.isRequired,
};

export default MapInfoWindow;
