import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";

// Components
import DoughnutChart        from "../../Utils/Charts/DoughnutChart";
import DashboardTotal       from "../../Utils/Components/DashboardTotal";



// Styles
const Totals = Styled.div.attrs(({ columns }) => ({ columns }))`
    display: grid;
    grid-template-columns: repeat(${(props) => props.columns}, minmax(0, 1fr));
    height: var(--dashboard-list);
    gap: var(--main-gap);
`;

const TotalsList = Styled.ul`
    display: flex;
    flex-direction: column;
    gap: var(--main-gap);
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        flex-grow: 2;
    }
`;



/**
 * The Commercial Retirements
 * @returns {React.ReactElement}
 */
function CommercialRetirements() {
    const { retirementTotals } = Store.useState("dashboardCommercial");


    // Do the Render
    return <DoughnutChart
        title="DASHBOARD_RETIREMENTS_TITLE"
        field="totalOrders"
        data={retirementTotals}
    >
        <Totals columns={retirementTotals.length}>
            {retirementTotals.map((elem) => <TotalsList key={elem.name}>
                <DashboardTotal
                    message={NLS.format("DASHBOARD_TOTAL_ORDERS_OF", elem.name)}
                    value={elem.totalOrders}
                />
                <DashboardTotal
                    message={NLS.format("DASHBOARD_GROSS_BILLING_OF", elem.name)}
                    value={elem.grossTotal}
                    isPrice
                />
                <DashboardTotal
                    message={NLS.format("DASHBOARD_AVERAGE_TICKET_OF", elem.name)}
                    value={elem.averageTicket}
                    isPrice
                />
            </TotalsList>)}
        </Totals>
    </DoughnutChart>;
}

export default CommercialRetirements;
