import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";



// Styled
const Container = Styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--main-gap);
    padding: calc(var(--main-gap) / 2) var(--main-gap);
    background-color: var(--lighter-gray);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
`;

const Radius = Styled.div`
    position: relative;
    b {
        position: absolute;
        top: 50%;
        left: 10px;
        pointer-events: none;
        transform: translateY(-50%);
        z-index: 1;
    }
    .input {
        margin-left: 40px;
        margin-right: 8px;
        max-width: 60px;
    }
    i {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
    }
`;



/**
 * The Map Toggle
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MapToggle(props) {
    const { message, show, radius, step } = props;

    const { options } = Store.useState("dashboardMap");
    const { setOption } = Store.useAction("dashboardMap");


    // Do the Render
    return <Container>
        <InputField
            type="toggle"
            name="show"
            label={message}
            value={options[show]}
            onChange={(name, value) => setOption(show, !options[show])}
            isSmall
        />
        <Radius>
            <b>{NLS.get("GENERAL_RADIUS")}</b>
            <InputField
                type="number"
                name="radius"
                value={options[radius]}
                onChange={(name, value) => setOption(radius, Number(value))}
                step={step}
                minValue={0}
                isSmall
            />
            <i>m</i>
        </Radius>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MapToggle.propTypes = {
    message : PropTypes.string.isRequired,
    show    : PropTypes.string.isRequired,
    radius  : PropTypes.string.isRequired,
    step    : PropTypes.number.isRequired,
};

export default MapToggle;
