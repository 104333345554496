import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";



// Styles
const Container = Styled.section`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: var(--main-gap);

    @media (max-width: 800px) {
        grid-template-columns: minmax(0, 1fr);
    }
`;



/**
 * The Dashboard Container
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardContainer(props) {
    const { children } = props;


    // Do the Render
    return <Container>
        {children}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardContainer.propTypes = {
    children : PropTypes.any,
};

export default DashboardContainer;
