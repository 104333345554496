import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";
import { Category }         from "Utils/API";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Category Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CategoryFilter(props) {
    const { open, filters, onClose, onSubmit } = props;

    const { macroCategories } = Store.useState("category");


    // The Initial Data
    const initialData = {
        categoryID      : 0,
        name            : "",
        macroCategoryID : 0,
    };

    // The Filter State
    const {
        loading, data, handleChange, handleSubmit,
    } = useFilter("category", open, initialData, filters, onSubmit);


    // Do the Render
    return <FilterDialog
        open={open}
        title="CATEGORIES_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            type="suggest"
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            suggestID="categoryID"
            suggestFetch={Category.search}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="macroCategoryID"
            label="MACRO_CATEGORIES_SINGULAR"
            options={macroCategories}
            value={data.macroCategoryID}
            onChange={handleChange}
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CategoryFilter.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    filters  : PropTypes.object,
};

export default CategoryFilter;
