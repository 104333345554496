import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Dashboard Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardFilter(props) {
    const { open, filters, onClose, onSubmit } = props;

    const {
        stateGroups, stateTypes, stateTypesPerGroup, retirementTypes,
        pharmacies, subsidiaries, subsidiariesPerPharmacy,
        macroCategories, categories, categoriesPerMacro,
        subCategories, subCategoriesPerMacro, subCategoriesPerCategory,
    } = Store.useState("dashboardFilter");


    // The Initial Data
    const initialData = {
        period           : 0,
        fromDate         : "",
        toDate           : "",
        stateGroupID     : [],
        stateID          : [],
        retirementTypeID : [],
        exclude          : 0,
        pharmacyID       : [],
        subsidiaryID     : [],
        macroCategoryID  : [],
        categoryID       : [],
        subCategoryID    : [],
        orderType        : [],
    };

    // The Filter State
    const {
        loading, data, handleChange, handlePeriod, handleSubmit,
    } = useFilter("dashboardFilter", open, initialData, filters, onSubmit);

    // Returns the selects based on the parents
    const getStateTypes    = Utils.useSelectList(loading, data.stateGroupID, stateTypes, stateTypesPerGroup);
    const getSubsidiaries  = Utils.useSelectList(loading, data.pharmacyID, subsidiaries, subsidiariesPerPharmacy);
    const getCategories    = Utils.useSelectList(loading, data.macroCategoryID, categories, categoriesPerMacro);
    const getSubCategories = Utils.useSubSelectList(loading, data.macroCategoryID, data.categoryID, subCategories, subCategoriesPerMacro, subCategoriesPerCategory);


    // Do the Render
    const hasPharmacies = pharmacies.length > 0;

    return <FilterDialog
        open={open}
        title="DASHBOARD_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        noAutoFocus
    >
        <Columns amount="3">
            <InputField
                type="select"
                name="period"
                label="PERIOD_NAME"
                value={data.period}
                onChange={handlePeriod}
                options={Period.getSelect()}
            />
            <InputField
                type="date"
                name="fromDate"
                label="GENERAL_FROM"
                value={data.fromDate}
                onChange={handleChange}
            />
            <InputField
                type="date"
                name="toDate"
                label="GENERAL_TO"
                value={data.toDate}
                onChange={handleChange}
            />
        </Columns>

        <InputField
            type="chooser"
            name="stateGroupID"
            label="STATE_GROUPS_NAME"
            options={stateGroups}
            value={data.stateGroupID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="stateID"
            label="STATES_NAME"
            options={getStateTypes()}
            value={data.stateID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="retirementTypeID"
            label="RETIREMENT_TYPES_SINGULAR"
            options={retirementTypes}
            value={data.retirementTypeID}
            onChange={handleChange}
        />

        <InputField
            isHidden={!hasPharmacies}
            type="toggle"
            name="exclude"
            label="GENERAL_EXCLUDE_FILTER"
            value={data.exclude}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!hasPharmacies}
            type="chooser"
            name="pharmacyID"
            label="PHARMACIES_NAME"
            options={pharmacies}
            value={data.pharmacyID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="subsidiaryID"
            label="SUBSIDIARIES_NAME"
            options={getSubsidiaries()}
            value={data.subsidiaryID}
            onChange={handleChange}
        />

        <InputField
            type="chooser"
            name="macroCategoryID"
            label="MACRO_CATEGORIES_NAME"
            options={macroCategories}
            value={data.macroCategoryID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="categoryID"
            label="CATEGORIES_NAME"
            options={getCategories()}
            value={data.categoryID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="subCategoryID"
            label="SUB_CATEGORIES_NAME"
            options={getSubCategories()}
            value={data.subCategoryID}
            onChange={handleChange}
        />
        <InputField
            isHidden={!hasPharmacies}
            type="chooser"
            name="orderType"
            label="DASHBOARD_ORDER_TYPES"
            options="SELECT_ORDER_TYPES"
            value={data.orderType}
            onChange={handleChange}
            withBorder
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardFilter.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    filters  : PropTypes.object,
};

export default DashboardFilter;
