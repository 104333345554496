import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Subsidiary Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SubsidiaryEdit(props) {
    const { open, elemID, pharmacyID, onClose, onSubmit } = props;

    const { pharmacies } = Store.useState("subsidiary");
    const { editElem } = Store.useAction("subsidiary");


    // The Initial Data
    const initialData = {
        subsidiaryID     : 0,
        pharmacyID       : 0,
        code             : "",
        name             : "",
        socialReason     : "",
        cuit             : "",
        email            : "",
        phone            : "",
        city             : "",
        commune          : "",
        location         : "",
        image            : "",
        latitude         : "",
        longitude        : "",
        color            : "",
        allowsAfterHours : 1,
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("subsidiary", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("subsidiary", open, elemID, { pharmacyID }, setElem);



    // Do the Render
    const isEdit = Boolean(elemID);

    return <EditDialog
        open={open}
        icon="subsidiary"
        title={isEdit ? "SUBSIDIARIES_EDIT_TITLE" : "SUBSIDIARIES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns>
            <InputField
                className="columns-double"
                type="select"
                name="pharmacyID"
                label="PHARMACIES_SINGULAR"
                options={pharmacies}
                value={data.pharmacyID}
                error={errors.pharmacyID}
                onChange={handleChange}
                isDisabled={!!pharmacyID}
                isRequired
            />

            <InputField
                name="name"
                label="GENERAL_NAME"
                value={data.name}
                error={errors.name}
                onChange={handleChange}
                isRequired
            />
            <InputField
                name="code"
                label="GENERAL_CODE"
                value={data.code}
                error={errors.code}
                onChange={handleChange}
                isRequired
            />

            <InputField
                name="socialReason"
                label="GENERAL_SOCIAL_REASON"
                value={data.socialReason}
                error={errors.socialReason}
                onChange={handleChange}
            />
            <InputField
                name="cuit"
                label="SUBSIDIARIES_CUIT"
                value={data.cuit}
                error={errors.cuit}
                onChange={handleChange}
            />

            <InputField
                type="email"
                name="email"
                label="GENERAL_EMAIL"
                value={data.email}
                error={errors.email}
                onChange={handleChange}
            />
            <InputField
                name="phone"
                label="GENERAL_PHONE"
                value={data.phone}
                error={errors.phone}
                onChange={handleChange}
            />

            <InputField
                name="city"
                label="GENERAL_CITY"
                value={data.city}
                error={errors.city}
                onChange={handleChange}
            />
            <InputField
                name="commune"
                label="GENERAL_COMMUNE"
                value={data.commune}
                error={errors.commune}
                onChange={handleChange}
            />
            <InputField
                name="location"
                label="GENERAL_LOCATION"
                value={data.location}
                error={errors.location}
                onChange={handleChange}
            />
            <InputField
                name="image"
                label="GENERAL_IMAGE_URL"
                value={data.image}
                error={errors.image}
                onChange={handleChange}
            />

            <InputField
                name="latitude"
                label="GENERAL_LATITUDE"
                value={data.latitude}
                error={errors.latitude}
                onChange={handleChange}
            />
            <InputField
                name="longitude"
                label="GENERAL_LONGITUDE"
                value={data.longitude}
                error={errors.longitude}
                onChange={handleChange}
            />

            <InputField
                type="color"
                name="color"
                label="GENERAL_COLOR"
                value={data.color}
                error={errors.color}
                onChange={handleChange}
            />
            <InputField
                type="toggle"
                name="allowsAfterHours"
                label="SUBSIDIARIES_ALLOWS_AFTER_HOURS"
                value={data.allowsAfterHours}
                error={errors.allowsAfterHours}
                onChange={handleChange}
                withBorder
            />
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SubsidiaryEdit.propTypes = {
    open       : PropTypes.bool.isRequired,
    onClose    : PropTypes.func.isRequired,
    onSubmit   : PropTypes.func.isRequired,
    elemID     : PropTypes.number,
    pharmacyID : PropTypes.number,
};

export default SubsidiaryEdit;
