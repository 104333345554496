import React                from "react";
import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";

// Components
import ViewTable            from "Components/Utils/View/ViewTable";
import ProviderLogView      from "Components/App/Log/ProviderLog/ProviderLogView";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";



/**
 * The Order Logs
 * @returns {React.ReactElement}
 */
function OrderLogs() {
    const { isSuperAdmin } = Store.useState("auth");
    const { logs } = Store.useState("order");


    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Do the Render
    if (!isSuperAdmin || !logs.length) {
        return <React.Fragment />;
    }
    return <>
        <ViewTable message="PROVIDERS_LOG_NAME">
            <Table noSorting notFixed>
                <TableHead>
                    <TableHeader field="createdTime" message="GENERAL_TIME"           isTitle minWidth="160" />
                    <TableHeader field="submitType"  message="GENERAL_TYPE"           />
                    <TableHeader field="provider"    message="PROVIDERS_LOG_PROVIDER" />
                    <TableHeader field="action"      message="PROVIDERS_LOG_ACTION"   />
                </TableHead>
                <TableBody>
                    {logs.map((elem) => <TableRow key={elem.logID} elemID={elem.logID}>
                        <TableCell message={elem.createdDate} />
                        <TableCell message={elem.submitType}  className={elem.errorClass} />
                        <TableCell message={elem.provider}    />
                        <TableCell message={elem.action}      />
                    </TableRow>)}
                </TableBody>
                <TableActionList onAction={startAction}>
                    <TableAction action="VIEW" message="PROVIDERS_LOG_VIEW_TITLE" />
                </TableActionList>
            </Table>
        </ViewTable>

        <ProviderLogView
            open={action.isVCE}
            elemID={elemID}
            onClose={endAction}
        />
    </>;
}

export default OrderLogs;
