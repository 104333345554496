import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";

// Components
import ProductGeneral       from "./View/ProductGeneral";
import ProductSubsidiaries  from "./View/ProductSubsidiaries";
import ProductUpdates       from "./View/ProductUpdates";
import ProductEdit          from "./Dialogs/ProductEdit";
import MedicineGeneral      from "Components/App/Medicine/Medicine/View/MedicineGeneral";
import MedicineDrugs        from "Components/App/Medicine/Medicine/View/MedicineDrugs";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";



// Styles
const Container = Styled(Content)`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const Info = Styled.div`
    display: flex;
    gap: 16px;

    @media (max-width: 700px) {
        flex-direction: column;
    }
`;

const Image = Styled.img`
    flex-shrink: 0;
    box-sizing: border-box;
    width: 250px;
    height: 100%;
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
    object-fit: contain;

    @media (max-width: 700px) {
        width: 100%;
        height: 250px;
    }
`;



/**
 * The Product Page
 * @returns {React.ReactElement}
 */
function ProductPage() {
    const { productID } = Navigate.useParams();
    const parent        = Navigate.useParent(1);
    const navigate      = Navigate.useGoto();

    const { charging, canEdit, elem, medicine, drugs } = Store.useState("product");
    const { fetchElem } = Store.useAction("product");

    // The Current State
    const [ showEdit, setShowEdit ] = React.useState(false);


    // Load the Data
    React.useEffect(() => {
        fetchElem(productID);
    }, [ productID ]);

    // Handles the Goto Medicine
    const handleGotoMedicine = () => {
        navigate("CATALOG", "MEDICINES", medicine.id);
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        setShowEdit(false);
        fetchElem(productID);
    };


    // Do the Render
    return <Main>
        <Header
            message={elem.name || "PRODUCTS_SINGULAR"}
            icon="campaign"
            href={parent}
        >
            <ActionList>
                <ActionItem
                    isHidden={!medicine.id}
                    icon="medicine"
                    message="MEDICINES_VIEW_TITLE"
                    onClick={() => handleGotoMedicine()}
                />
                <ActionItem
                    isHidden={!canEdit}
                    action="EDIT"
                    onClick={() => setShowEdit(true)}
                />
            </ActionList>
        </Header>

        <Container isLoading={charging}>
            <Info>
                {elem.image && <Image
                    src={elem.image}
                    alt={elem.name}
                />}
                <ProductGeneral />
            </Info>
            <MedicineGeneral elem={medicine} />
            <MedicineDrugs list={drugs} />
            <ProductSubsidiaries />
            <ProductUpdates />
        </Container>

        <ProductEdit
            open={showEdit}
            elemID={productID}
            onSubmit={handleEdit}
            onClose={() => setShowEdit(false)}
        />
    </Main>;
}

export default ProductPage;
