import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import PageContainer        from "Dashboard/Components/Page/PageContainer";
import PageColumns          from "Dashboard/Components/Page/PageColumns";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Subsidiary View Dialog
 * @returns {React.ReactElement}
 */
function SubsidiaryView() {
    const { subsidiaryID } = Navigate.useParams();

    const { charging, elem } = Store.useState("subsidiary");
    const { fetchElem } = Store.useAction("subsidiary");


    // Load the Data
    React.useEffect(() => {
        if (subsidiaryID) {
            fetchElem(subsidiaryID);
        }
    }, [ subsidiaryID ]);


    // Do the Render
    return <Main>
        <Header message="GENERAL_INFO" icon="subsidiary" />
        <Content isLoading={charging}>
            <PageContainer>
                <PageColumns>
                    <ViewField
                        className="columns-double"
                        label="PHARMACIES_SINGULAR"
                        value={elem.pharmacyName}
                    />
                    <ViewField
                        label="GENERAL_SOCIAL_REASON"
                        value={elem.socialReason}
                    />
                    <ViewField
                        label="SUBSIDIARIES_CUIT"
                        value={elem.cuit}
                    />

                    <ViewField
                        label="GENERAL_CODE"
                        value={elem.code}
                    />
                    <ViewField
                        label="GENERAL_NAME"
                        value={elem.name}
                    />

                    <ViewField
                        label="GENERAL_EMAIL"
                        value={elem.email}
                    />
                    <ViewField
                        label="GENERAL_PHONE"
                        value={elem.phone}
                    />

                    <ViewField
                        label="GENERAL_CITY"
                        value={elem.city}
                    />
                    <ViewField
                        label="GENERAL_COMMUNE"
                        value={elem.commune}
                    />
                    <ViewField
                        label="GENERAL_LOCATION"
                        value={elem.location}
                    />

                    <ViewField
                        label="GENERAL_LATITUDE"
                        value={elem.latitude}
                    />
                    <ViewField
                        label="GENERAL_LONGITUDE"
                        value={elem.longitude}
                    />
                </PageColumns>
            </PageContainer>
        </Content>
    </Main>;
}

export default SubsidiaryView;
