import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import DailyTotals          from "./Components/DailyTotals";
import DailyChartBar        from "./Components/DailyChartBar";
import DailyChartLine       from "./Components/DailyChartLine";
import DailyAnimation       from "./Components/DailyAnimation";

// Dashboard
import Columns              from "Dashboard/Components/Form/Columns";



// Styles
const Container = Styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--main-gap);
`;

const Charts = Styled(Columns)`
    @media (max-width: 1200px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;



/**
 * The Daily Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DailyContent(props) {
    const { isHidden } = props;

    const { currentDate } = Store.useState("dashboardDaily");
    const { fetchList } = Store.useAction("dashboardDaily");

    // The References
    const timerRef = React.useRef(0);

    // The Current State
    const [ initialized, setInitialized ] = React.useState(false);
    const [ update,      setUpdate      ] = React.useState(0);


    // Initial Fetch
    React.useEffect(() => {
        if (!isHidden) {
            fetchList(currentDate.time);
            setInitialized(true);
            Utils.setUpdateTimeout(timerRef, setUpdate, update);
        }
        return () => {
            Utils.clearTimeout(timerRef);
        };
    }, [ isHidden ]);

    // Handle the Update
    React.useEffect(() => {
        if (initialized) {
            fetchList(currentDate.time);
            Utils.clearTimeout(timerRef);
            Utils.setUpdateTimeout(timerRef, setUpdate, update);
        }
    }, [ update, currentDate.time ]);


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <>
        <Container>
            <DailyTotals />
            <Charts>
                <DailyChartBar currentDate={currentDate} />
                <DailyChartLine currentDate={currentDate} />
            </Charts>
        </Container>
        <DailyAnimation isToday={currentDate.isToday} />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DailyContent.propTypes = {
    isHidden : PropTypes.bool.isRequired,
};

export default DailyContent;
