import React                from "react";
import PropTypes            from "prop-types";

// Components
import ViewTable            from "Components/Utils/View/ViewTable";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";



/**
 * The Medicine Drugs
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MedicineDrugs(props) {
    const { list } = props;


    // Do the Render
    if (!list || !list.length) {
        return <React.Fragment />;
    }

    return <ViewTable message="MEDICINES_DRUGS">
        <Table noSorting notFixed rightSpace>
            <TableHead>
                <TableHeader field="drugTypeName" message="DRUG_TYPES_SINGULAR" isTitle />
                <TableHeader field="potency"      message="GENERAL_POTENCY"     />
            </TableHead>
            <TableBody>
                {list.map((elem) => <TableRow key={elem.medicineDrugID} elemID={elem.medicineDrugID}>
                    <TableCell message={elem.drugTypeName} />
                    <TableCell message={elem.potencyText}  />
                </TableRow>)}
            </TableBody>
        </Table>
    </ViewTable>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MedicineDrugs.propTypes = {
    list : PropTypes.array,
};

export default MedicineDrugs;
