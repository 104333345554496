import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Sub Category Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SubCategoryEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { macroCategories, categoriesMacro } = Store.useState("subCategory");
    const { editElem } = Store.useAction("subCategory");


    // The Initial Data
    const initialData = {
        subCategoryID   : 0,
        macroCategoryID : 0,
        categoryID      : 0,
        name            : "",
        position        : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("subCategory", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("subCategory", open, elemID, null, setElem);


    // Do the Render
    return <EditDialog
        open={open}
        icon="sub-category"
        title={elemID ? "SUB_CATEGORIES_EDIT_TITLE" : "SUB_CATEGORIES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="SUB_CATEGORIES_SINGULAR"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="macroCategoryID"
            label="MACRO_CATEGORIES_SINGULAR"
            options={macroCategories}
            value={data.macroCategoryID}
            error={errors.macroCategoryID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="categoryID"
            label="CATEGORIES_SINGULAR"
            options={data.macroCategoryID ? categoriesMacro[data.macroCategoryID] : []}
            value={data.categoryID}
            error={errors.categoryID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SubCategoryEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default SubCategoryEdit;
