import Ajax                 from "Dashboard/Core/Ajax";



// Auth actions
export const Auth = {
    "login"                 : (data) => Ajax.post("/session/login",                             data),
    "loginAs"               : (data) => Ajax.post("/session/loginAs",                           data),
    "tokenLogin"            : (data) => Ajax.post("/session/tokenLogin",                        data),
    "refreshTokenLogin"     : (data) => Ajax.post("/session/refreshTokenLogin",                 data),
    "logout"                : (data) => Ajax.post("/session/logout",                            data),
    "logoutAs"              : (data) => Ajax.post("/session/logoutAs",                          data),
    "requestReset"          : (data) => Ajax.post("/session/requestReset",                      data),
    "verifyReset"           : (data) => Ajax.post("/session/verifyReset",                       data),
    "resetPass"             : (data) => Ajax.post("/session/resetPass",                         data),
    "changePass"            : (data) => Ajax.post("/session/changePass",                        data),
    "deleteRefreshToken"    : (data) => Ajax.post("/session/deleteRefreshToken",                data),
};
export const Profile = {
    "search"                : (data) => Ajax.post("/profile/search",                            data),
    "get"                   : (data) => Ajax.post("/profile/get",                               data),
    "edit"                  : (data) => Ajax.post("/profile/edit",                              data),
    "upload"                : (data) => Ajax.post("/profile/upload",                            data),
    "delete"                : (data) => Ajax.post("/profile/delete",                            data),
};



// Dashboard Actions
export const DashboardFilter = {
    "getAll"                : (data) => Ajax.post("/dashboard/filter/getAll",                   data),
};
export const DashboardDaily = {
    "getAll"                : (data) => Ajax.post("/dashboard/daily/getAll",                    data),
};
export const DashboardCommercial = {
    "getAll"                : (data) => Ajax.post("/dashboard/commercial/getAll",               data),
};
export const DashboardOperations = {
    "getAll"                : (data) => Ajax.post("/dashboard/operations/getAll",               data),
};
export const DashboardCatalog = {
    "getAll"                : (data) => Ajax.post("/dashboard/catalog/getAll",                  data),
};
export const DashboardMap = {
    "getAll"                : (data) => Ajax.post("/dashboard/map/getAll",                      data),
};
export const DashboardProcess = {
    "getAll"                : (data) => Ajax.post("/dashboard/process/getAll",                  data),
    "getOne"                : (data) => Ajax.post("/dashboard/process/getOne",                  data),
    "startUsing"            : (data) => Ajax.post("/dashboard/process/startUsing",              data),
    "stopUsing"             : (data) => Ajax.post("/dashboard/process/stopUsing",               data),
    "getMessages"           : (data) => Ajax.post("/dashboard/process/getMessages",             data),
    "sendMessage"           : (data) => Ajax.post("/dashboard/process/sendMessage",             data),
    "edit"                  : (data) => Ajax.post("/dashboard/process/edit",                    data),
    "editData"              : (data) => Ajax.post("/dashboard/process/editData",                data),
    "editRecipe"            : (data) => Ajax.post("/dashboard/process/editRecipe",              data),
    "editCoverage"          : (data) => Ajax.post("/dashboard/process/editCoverage",            data),
    "approve"               : (data) => Ajax.post("/dashboard/process/approve",                 data),
    "reject"                : (data) => Ajax.post("/dashboard/process/reject",                  data),
    "retire"                : (data) => Ajax.post("/dashboard/process/retire",                  data),
    "retired"               : (data) => Ajax.post("/dashboard/process/retired",                 data),
    "cancel"                : (data) => Ajax.post("/dashboard/process/cancel",                  data),
    "nullify"               : (data) => Ajax.post("/dashboard/process/nullify",                 data),
};



// Business Actions
export const Pharmacy = {
    "getAll"                : (data) => Ajax.post("/pharmacy/getAll",                           data),
    "getOne"                : (data) => Ajax.post("/pharmacy/getOne",                           data),
    "edit"                  : (data) => Ajax.post("/pharmacy/edit",                             data),
    "delete"                : (data) => Ajax.post("/pharmacy/delete",                           data),
};
export const User = {
    "getAll"                : (data) => Ajax.post("/user/getAll",                               data),
    "getOne"                : (data) => Ajax.post("/user/getOne",                               data),
    "getEditData"           : (data) => Ajax.post("/user/getEditData",                          data),
    "edit"                  : (data) => Ajax.post("/user/edit",                                 data),
    "delete"                : (data) => Ajax.post("/user/delete",                               data),
};
export const Contact = {
    "getAll"                : (data) => Ajax.post("/contact/getAll",                            data),
    "getOne"                : (data) => Ajax.post("/contact/getOne",                            data),
    "getEditData"           : (data) => Ajax.post("/contact/getEditData",                       data),
    "search"                : (data) => Ajax.post("/contact/search",                            data),
    "edit"                  : (data) => Ajax.post("/contact/edit",                              data),
    "delete"                : (data) => Ajax.post("/contact/delete",                            data),
};



// Subsidiary Actions
export const Subsidiary = {
    "getAll"                : (data) => Ajax.post("/subsidiary/getAll",                         data),
    "getOne"                : (data) => Ajax.post("/subsidiary/getOne",                         data),
    "getEditData"           : (data) => Ajax.post("/subsidiary/getEditData",                    data),
    "getFilterData"         : (data) => Ajax.post("/subsidiary/getFilterData",                  data),
    "edit"                  : (data) => Ajax.post("/subsidiary/edit",                           data),
    "delete"                : (data) => Ajax.post("/subsidiary/delete",                         data),
};
export const SubsidiaryGateway = {
    "getAll"                : (data) => Ajax.post("/subsidiary/gateway/getAll",                 data),
    "getAllForSubsidiary"   : (data) => Ajax.post("/subsidiary/gateway/getAllForSubsidiary",    data),
    "getFilterData"         : (data) => Ajax.post("/subsidiary/gateway/getFilterData",          data),
    "edit"                  : (data) => Ajax.post("/subsidiary/gateway/edit",                   data),
    "editAll"               : (data) => Ajax.post("/subsidiary/gateway/editAll",                data),
};
export const SubsidiaryLogistic = {
    "getAll"                : (data) => Ajax.post("/subsidiary/logistic/getAll",                data),
    "editAll"               : (data) => Ajax.post("/subsidiary/logistic/editAll",               data),
};
export const SubsidiarySchedule = {
    "getAll"                : (data) => Ajax.post("/subsidiary/schedule/getAll",                data),
    "getOne"                : (data) => Ajax.post("/subsidiary/schedule/getOne",                data),
    "edit"                  : (data) => Ajax.post("/subsidiary/schedule/edit",                  data),
    "delete"                : (data) => Ajax.post("/subsidiary/schedule/delete",                data),
};



// Order Actions
export const Order = {
    "getAll"                : (data) => Ajax.post("/order/getAll",                              data),
    "getOne"                : (data) => Ajax.post("/order/getOne",                              data),
    "getFilterData"         : (data) => Ajax.post("/order/getFilterData",                       data),
    "activate"              : (data) => Ajax.post("/order/activate",                            data),
    "retired"               : (data) => Ajax.post("/order/retired",                             data),
    "cancel"                : (data) => Ajax.post("/order/cancel",                              data),
    "export"                : (data) => Ajax.url("/order/export",                               data),
};
export const Client = {
    "getAll"                : (data) => Ajax.post("/client/getAll",                             data),
    "getOne"                : (data) => Ajax.post("/client/getOne",                             data),
    "edit"                  : (data) => Ajax.post("/client/edit",                               data),
    "delete"                : (data) => Ajax.post("/client/delete",                             data),
};



// Catalog Actions
export const Product = {
    "getAll"                : (data) => Ajax.post("/product/getAll",                            data),
    "getOne"                : (data) => Ajax.post("/product/getOne",                            data),
    "getEditData"           : (data) => Ajax.post("/product/getEditData",                       data),
    "getFilterData"         : (data) => Ajax.post("/product/getFilterData",                     data),
    "search"                : (data) => Ajax.post("/product/search",                            data),
    "edit"                  : (data) => Ajax.post("/product/edit",                              data),
    "editMulti"             : (data) => Ajax.post("/product/editMulti",                         data),
    "delete"                : (data) => Ajax.post("/product/delete",                            data),
    "export"                : (data) => Ajax.url("/product/export",                             data),
};
export const MacroCategory = {
    "getAll"                : (data) => Ajax.post("/macroCategory/getAll",                      data),
    "getOne"                : (data) => Ajax.post("/macroCategory/getOne",                      data),
    "search"                : (data) => Ajax.post("/macroCategory/search",                      data),
    "edit"                  : (data) => Ajax.post("/macroCategory/edit",                        data),
    "delete"                : (data) => Ajax.post("/macroCategory/delete",                      data),
};
export const Category = {
    "getAll"                : (data) => Ajax.post("/category/getAll",                           data),
    "getOne"                : (data) => Ajax.post("/category/getOne",                           data),
    "getEditData"           : (data) => Ajax.post("/category/getEditData",                      data),
    "search"                : (data) => Ajax.post("/category/search",                           data),
    "edit"                  : (data) => Ajax.post("/category/edit",                             data),
    "delete"                : (data) => Ajax.post("/category/delete",                           data),
};
export const SubCategory = {
    "getAll"                : (data) => Ajax.post("/subCategory/getAll",                        data),
    "getOne"                : (data) => Ajax.post("/subCategory/getOne",                        data),
    "getEditData"           : (data) => Ajax.post("/subCategory/getEditData",                   data),
    "getFilterData"         : (data) => Ajax.post("/subCategory/getFilterData",                 data),
    "search"                : (data) => Ajax.post("/subCategory/search",                        data),
    "edit"                  : (data) => Ajax.post("/subCategory/edit",                          data),
    "delete"                : (data) => Ajax.post("/subCategory/delete",                        data),
};
export const Space = {
    "getAll"                : (data) => Ajax.post("/space/getAll",                              data),
    "getOne"                : (data) => Ajax.post("/space/getOne",                              data),
    "getEditData"           : (data) => Ajax.post("/space/getEditData",                         data),
    "edit"                  : (data) => Ajax.post("/space/edit",                                data),
    "delete"                : (data) => Ajax.post("/space/delete",                              data),
};
export const Banner = {
    "getAll"                : (data) => Ajax.post("/banner/getAll",                             data),
    "getOne"                : (data) => Ajax.post("/banner/getOne",                             data),
    "getEditData"           : (data) => Ajax.post("/banner/getEditData",                        data),
    "edit"                  : (data) => Ajax.post("/banner/edit",                               data),
    "delete"                : (data) => Ajax.post("/banner/delete",                             data),
};



// Medicine Actions
export const Medicine = {
    "getAll"                : (data) => Ajax.post("/medicine/getAll",                           data),
    "getOne"                : (data) => Ajax.post("/medicine/getOne",                           data),
    "getEditData"           : (data) => Ajax.post("/medicine/getEditData",                      data),
    "getFilterData"         : (data) => Ajax.post("/medicine/getFilterData",                    data),
    "edit"                  : (data) => Ajax.post("/medicine/edit",                             data),
    "export"                : (data) => Ajax.url("/medicine/export",                            data),
};
export const Laboratory = {
    "getAll"                : (data) => Ajax.post("/laboratory/getAll",                         data),
    "getOne"                : (data) => Ajax.post("/laboratory/getOne",                         data),
    "search"                : (data) => Ajax.post("/laboratory/search",                         data),
    "edit"                  : (data) => Ajax.post("/laboratory/edit",                           data),
    "delete"                : (data) => Ajax.post("/laboratory/delete",                         data),
};
export const MonoDrug = {
    "getAll"                : (data) => Ajax.post("/monoDrug/getAll",                           data),
    "getOne"                : (data) => Ajax.post("/monoDrug/getOne",                           data),
    "search"                : (data) => Ajax.post("/monoDrug/search",                           data),
    "edit"                  : (data) => Ajax.post("/monoDrug/edit",                             data),
    "delete"                : (data) => Ajax.post("/monoDrug/delete",                           data),
};
export const DrugType = {
    "getAll"                : (data) => Ajax.post("/drugType/getAll",                           data),
    "getOne"                : (data) => Ajax.post("/drugType/getOne",                           data),
    "search"                : (data) => Ajax.post("/drugType/search",                           data),
    "edit"                  : (data) => Ajax.post("/drugType/edit",                             data),
    "delete"                : (data) => Ajax.post("/drugType/delete",                           data),
};
export const SaleType = {
    "getAll"                : (data) => Ajax.post("/saleType/getAll",                           data),
    "getOne"                : (data) => Ajax.post("/saleType/getOne",                           data),
    "search"                : (data) => Ajax.post("/saleType/search",                           data),
    "edit"                  : (data) => Ajax.post("/saleType/edit",                             data),
    "delete"                : (data) => Ajax.post("/saleType/delete",                           data),
};
export const HealthType = {
    "getAll"                : (data) => Ajax.post("/healthType/getAll",                         data),
    "getOne"                : (data) => Ajax.post("/healthType/getOne",                         data),
    "search"                : (data) => Ajax.post("/healthType/search",                         data),
    "edit"                  : (data) => Ajax.post("/healthType/edit",                           data),
    "delete"                : (data) => Ajax.post("/healthType/delete",                         data),
};
export const SizeType = {
    "getAll"                : (data) => Ajax.post("/sizeType/getAll",                           data),
    "getOne"                : (data) => Ajax.post("/sizeType/getOne",                           data),
    "search"                : (data) => Ajax.post("/sizeType/search",                           data),
    "edit"                  : (data) => Ajax.post("/sizeType/edit",                             data),
    "delete"                : (data) => Ajax.post("/sizeType/delete",                           data),
};
export const ShapeType = {
    "getAll"                : (data) => Ajax.post("/shapeType/getAll",                          data),
    "getOne"                : (data) => Ajax.post("/shapeType/getOne",                          data),
    "search"                : (data) => Ajax.post("/shapeType/search",                          data),
    "edit"                  : (data) => Ajax.post("/shapeType/edit",                            data),
    "delete"                : (data) => Ajax.post("/shapeType/delete",                          data),
};
export const PotencyType = {
    "getAll"                : (data) => Ajax.post("/potencyType/getAll",                        data),
    "getOne"                : (data) => Ajax.post("/potencyType/getOne",                        data),
    "search"                : (data) => Ajax.post("/potencyType/search",                        data),
    "edit"                  : (data) => Ajax.post("/potencyType/edit",                          data),
    "delete"                : (data) => Ajax.post("/potencyType/delete",                        data),
};
export const UnitType = {
    "getAll"                : (data) => Ajax.post("/unitType/getAll",                           data),
    "getOne"                : (data) => Ajax.post("/unitType/getOne",                           data),
    "search"                : (data) => Ajax.post("/unitType/search",                           data),
    "edit"                  : (data) => Ajax.post("/unitType/edit",                             data),
    "delete"                : (data) => Ajax.post("/unitType/delete",                           data),
};
export const AdministrationRoute = {
    "getAll"                : (data) => Ajax.post("/administrationRoute/getAll",                data),
    "getOne"                : (data) => Ajax.post("/administrationRoute/getOne",                data),
    "search"                : (data) => Ajax.post("/administrationRoute/search",                data),
    "edit"                  : (data) => Ajax.post("/administrationRoute/edit",                  data),
    "delete"                : (data) => Ajax.post("/administrationRoute/delete",                data),
};
export const PharmaAction = {
    "getAll"                : (data) => Ajax.post("/pharmaAction/getAll",                       data),
    "getOne"                : (data) => Ajax.post("/pharmaAction/getOne",                       data),
    "search"                : (data) => Ajax.post("/pharmaAction/search",                       data),
    "edit"                  : (data) => Ajax.post("/pharmaAction/edit",                         data),
    "delete"                : (data) => Ajax.post("/pharmaAction/delete",                       data),
};



// Change Actions
export const ChangeProduct = {
    "getAll"                : (data) => Ajax.post("/change/product/getAll",                     data),
    "getOne"                : (data) => Ajax.post("/change/product/getOne",                     data),
    "startUsing"            : (data) => Ajax.post("/change/product/startUsing",                 data),
    "stopUsing"             : (data) => Ajax.post("/change/product/stopUsing",                  data),
    "edit"                  : (data) => Ajax.post("/change/product/edit",                       data),
};
export const ChangeCatalog = {
    "getAll"                : (data) => Ajax.post("/change/catalog/getAll",                     data),
    "getOne"                : (data) => Ajax.post("/change/catalog/getOne",                     data),
    "startUsing"            : (data) => Ajax.post("/change/catalog/startUsing",                 data),
    "stopUsing"             : (data) => Ajax.post("/change/catalog/stopUsing",                  data),
    "process"               : (data) => Ajax.post("/change/catalog/process",                    data),
    "validate"              : (data) => Ajax.post("/change/catalog/validate",                   data),
    "multiValidate"         : (data) => Ajax.post("/change/catalog/multiValidate",              data),
};




// Logistic Actions
export const Logistic = {
    "getAll"                : (data) => Ajax.post("/logistic/getAll",                           data),
    "getOne"                : (data) => Ajax.post("/logistic/getOne",                           data),
    "edit"                  : (data) => Ajax.post("/logistic/edit",                             data),
};
export const RetirementType = {
    "getAll"                : (data) => Ajax.post("/retirementType/getAll",                     data),
    "getOne"                : (data) => Ajax.post("/retirementType/getOne",                     data),
    "edit"                  : (data) => Ajax.post("/retirementType/edit",                       data),
};



// Transaction Actions
export const Transaction = {
    "getAll"                : (data) => Ajax.post("/transaction/getAll",                        data),
    "getFilterData"         : (data) => Ajax.post("/transaction/getFilterData",                 data),
    "export"                : (data) => Ajax.url("/transaction/export",                         data),
};
export const TransactionType = {
    "getAll"                : (data) => Ajax.post("/transactionType/getAll",                    data),
    "getOne"                : (data) => Ajax.post("/transactionType/getOne",                    data),
    "edit"                  : (data) => Ajax.post("/transactionType/edit",                      data),
    "delete"                : (data) => Ajax.post("/transactionType/delete",                    data),
};
export const Gateway = {
    "getAll"                : (data) => Ajax.post("/gateway/getAll",                            data),
    "getOne"                : (data) => Ajax.post("/gateway/getOne",                            data),
    "edit"                  : (data) => Ajax.post("/gateway/edit",                              data),
};
export const PaymentType = {
    "getAll"                : (data) => Ajax.post("/paymentType/getAll",                        data),
    "getOne"                : (data) => Ajax.post("/paymentType/getOne",                        data),
    "edit"                  : (data) => Ajax.post("/paymentType/edit",                          data),
    "delete"                : (data) => Ajax.post("/paymentType/delete",                        data),
};
export const PaymentMethod = {
    "getAll"                : (data) => Ajax.post("/paymentMethod/getAll",                      data),
    "getOne"                : (data) => Ajax.post("/paymentMethod/getOne",                      data),
    "edit"                  : (data) => Ajax.post("/paymentMethod/edit",                        data),
    "delete"                : (data) => Ajax.post("/paymentMethod/delete",                      data),
};
export const CardType = {
    "getAll"                : (data) => Ajax.post("/cardType/getAll",                           data),
    "getOne"                : (data) => Ajax.post("/cardType/getOne",                           data),
    "edit"                  : (data) => Ajax.post("/cardType/edit",                             data),
    "delete"                : (data) => Ajax.post("/cardType/delete",                           data),
};
export const Bank = {
    "getAll"                : (data) => Ajax.post("/bank/getAll",                               data),
    "getOne"                : (data) => Ajax.post("/bank/getOne",                               data),
    "edit"                  : (data) => Ajax.post("/bank/edit",                                 data),
    "delete"                : (data) => Ajax.post("/bank/delete",                               data),
};
export const Commission = {
    "getAll"                : (data) => Ajax.post("/commission/getAll",                         data),
    "getOne"                : (data) => Ajax.post("/commission/getOne",                         data),
    "getEditData"           : (data) => Ajax.post("/commission/getEditData",                    data),
    "edit"                  : (data) => Ajax.post("/commission/edit",                           data),
    "delete"                : (data) => Ajax.post("/commission/delete",                         data),
};



// Setup Actions
export const Admin = {
    "getAll"                : (data) => Ajax.post("/admin/getAll",                              data),
    "getOne"                : (data) => Ajax.post("/admin/getOne",                              data),
    "edit"                  : (data) => Ajax.post("/admin/edit",                                data),
    "delete"                : (data) => Ajax.post("/admin/delete",                              data),
};
export const Objective = {
    "getAll"                : (data) => Ajax.post("/objective/getAll",                          data),
    "getOne"                : (data) => Ajax.post("/objective/getOne",                          data),
    "edit"                  : (data) => Ajax.post("/objective/edit",                            data),
    "delete"                : (data) => Ajax.post("/objective/delete",                          data),
};
export const Holiday = {
    "getAll"                : (data) => Ajax.post("/holiday/getAll",                            data),
    "getOne"                : (data) => Ajax.post("/holiday/getOne",                            data),
    "edit"                  : (data) => Ajax.post("/holiday/edit",                              data),
    "delete"                : (data) => Ajax.post("/holiday/delete",                            data),
};
export const State = {
    "getAll"                : (data) => Ajax.post("/state/getAll",                              data),
    "getOne"                : (data) => Ajax.post("/state/getOne",                              data),
    "edit"                  : (data) => Ajax.post("/state/edit",                                data),
};
export const StateGroup = {
    "getAll"                : (data) => Ajax.post("/stateGroup/getAll",                         data),
    "getOne"                : (data) => Ajax.post("/stateGroup/getOne",                         data),
    "edit"                  : (data) => Ajax.post("/stateGroup/edit",                           data),
    "delete"                : (data) => Ajax.post("/stateGroup/delete",                         data),
};
export const CoverageType = {
    "getAll"                : (data) => Ajax.post("/coverageType/getAll",                       data),
    "getOne"                : (data) => Ajax.post("/coverageType/getOne",                       data),
    "edit"                  : (data) => Ajax.post("/coverageType/edit",                         data),
    "delete"                : (data) => Ajax.post("/coverageType/delete",                       data),
};
export const RejectReason = {
    "getAll"                : (data) => Ajax.post("/rejectReason/getAll",                       data),
    "getOne"                : (data) => Ajax.post("/rejectReason/getOne",                       data),
    "edit"                  : (data) => Ajax.post("/rejectReason/edit",                         data),
    "delete"                : (data) => Ajax.post("/rejectReason/delete",                       data),
};
export const CancelReason = {
    "getAll"                : (data) => Ajax.post("/cancelReason/getAll",                       data),
    "getOne"                : (data) => Ajax.post("/cancelReason/getOne",                       data),
    "edit"                  : (data) => Ajax.post("/cancelReason/edit",                         data),
    "delete"                : (data) => Ajax.post("/cancelReason/delete",                       data),
};
export const Tooltip = {
    "getAll"                : (data) => Ajax.post("/tooltip/getAll",                            data),
    "getOne"                : (data) => Ajax.post("/tooltip/getOne",                            data),
    "edit"                  : (data) => Ajax.post("/tooltip/edit",                              data),
};
export const Media = {
    "getAll"                : (data) => Ajax.post("/media/getAll",                              data),
    "create"                : (data) => Ajax.post("/media/create",                              data),
    "upload"                : (data) => Ajax.post("/media/upload",                              data),
    "rename"                : (data) => Ajax.post("/media/rename",                              data),
    "move"                  : (data) => Ajax.post("/media/move",                                data),
    "delete"                : (data) => Ajax.post("/media/delete",                              data),
};
export const Document = {
    "getAll"                : (data) => Ajax.post("/document/getAll",                           data),
    "getUrls"               : (data) => Ajax.post("/document/getUrls",                          data),
    "getOne"                : (data) => Ajax.post("/document/getOne",                           data),
    "getEditData"           : (data) => Ajax.post("/document/getEditData",                      data),
    "edit"                  : (data) => Ajax.post("/document/edit",                             data),
    "save"                  : (data) => Ajax.post("/document/save",                             data),
    "delete"                : (data) => Ajax.post("/document/delete",                           data),
};



// Email Actions
export const EmailTemplate = {
    "getAll"                : (data) => Ajax.post("/email/template/getAll",                     data),
    "getOne"                : (data) => Ajax.post("/email/template/getOne",                     data),
};
export const EmailQueue = {
    "getAll"                : (data) => Ajax.get("/email/queue/getAll",                         data),
    "getOne"                : (data) => Ajax.get("/email/queue/getOne",                         data),
    "resend"                : (data) => Ajax.get("/email/queue/resend",                         data),
};
export const EmailWhiteList = {
    "getAll"                : (data) => Ajax.get("/email/whiteList/getAll",                     data),
    "getOne"                : (data) => Ajax.get("/email/whiteList/getOne",                     data),
    "edit"                  : (data) => Ajax.post("/email/whiteList/edit",                      data),
    "remove"                : (data) => Ajax.post("/email/whiteList/remove",                    data),
};



// Log Actions
export const ProviderLog = {
    "getAll"                : (data) => Ajax.post("/log/provider/getAll",                       data),
    "getOne"                : (data) => Ajax.post("/log/provider/getOne",                       data),
};
export const ActionLog = {
    "getAll"                : (data) => Ajax.post("/log/action/getAll",                         data),
    "getAllForClient"       : (data) => Ajax.post("/log/action/getAllForClient",                data),
    "getAllForUser"         : (data) => Ajax.post("/log/action/getAllForUser",                  data),
};
export const ErrorLog = {
    "getAll"                : (data) => Ajax.post("/log/error/getAll",                          data),
    "getOne"                : (data) => Ajax.post("/log/error/getOne",                          data),
    "markResolved"          : (data) => Ajax.post("/log/error/markResolved",                    data),
    "delete"                : (data) => Ajax.post("/log/error/delete",                          data),
};
