import React                from "react";
import PropTypes            from "prop-types";
import { Bar }              from "react-chartjs-2";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Components
import DashboardTitle       from "../../Utils/Components/DashboardTitle";



// Styles
const Container = Styled.section`
    flex-grow: 2;
    box-sizing: border-box;
    padding: var(--main-gap);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
`;

const Chart = Styled.div`
    height: calc(440px - 24px - 2 * var(--main-gap));
`;



/**
 * The Commercial Chart Bar
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CommercialChartBar(props) {
    const { title, data : {
        labels, billingTotals, netTotals,
    }, isDaily } = props;

    const { holidays } = Store.useState("dashboardCommercial");


    // Do the Render
    return <Container>
        <DashboardTitle message={title} />
        <Chart>
            <Bar
                options={{
                    maintainAspectRatio : false,
                    interaction : {
                        mode      : "index",
                        intersect : false,
                    },
                    scales : {
                        x : Commons.getDateScale(holidays, isDaily),
                        y : Commons.getScaleOptions(true, false, false),
                    },
                    plugins : {
                        legend  : Commons.getLegendOptions("top"),
                        tooltip : Commons.getTooltipOptions(false, {
                            title(context) {
                                if (!isDaily) {
                                    return undefined;
                                }

                                const { date, text } = Commons.crateDateFromLabel(context[0].label);
                                const holiday = Utils.getValue(holidays, "day", date.day, "name");
                                if (holiday) {
                                    return `${date.getDayName()}, ${text} (${holiday})`;
                                }
                                return `${date.getDayName()}, ${text}`;
                            },
                        }),
                    },
                }}
                data={{
                    labels   : labels,
                    datasets : [
                        {
                            // @ts-ignore
                            prefix           : "$ ",
                            order            : 0,
                            yAxisID          : "y",
                            label            : NLS.get("DASHBOARD_GROSS_BILLING"),
                            data             : billingTotals,
                            borderColor      : "rgb(59, 199, 181)",
                            backgroundColor  : "rgb(59, 199, 181)",
                            pointBorderWidth : 4,
                            borderRadius     : 6,
                        },
                        {
                            // @ts-ignore
                            prefix           : "$ ",
                            order            : 1,
                            yAxisID          : "y",
                            label            : NLS.get("DASHBOARD_NET_BILLING"),
                            data             : netTotals,
                            borderColor      : "rgb(2, 131, 200)",
                            backgroundColor  : "rgb(2, 131, 200)",
                            pointBorderWidth : 4,
                            borderRadius     : 6,
                        },
                    ],
                }}
            />
        </Chart>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CommercialChartBar.propTypes = {
    title   : PropTypes.string.isRequired,
    data    : PropTypes.object.isRequired,
    isDaily : PropTypes.bool,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
CommercialChartBar.defaultProps = {
    isDaily : false,
};

export default CommercialChartBar;
