import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";

// Dashboard
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Form = Styled.form`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (max-width: 1000px) {
        height: auto;
    }
`;

const Footer = Styled.footer`
    padding: 8px 8px 8px 16px;
    background-color: var(--lighter-gray);
    text-align: right;
    border-radius: var(--border-radius);
`;



/**
 * The Settings Form
 * @returns {React.ReactElement}
 */
function SettingsForm() {
    const { isAnyAdmin } = Store.useState("auth");
    const { elem, edition } = Store.useState("profile");
    const { fetchElem, editElem } = Store.useAction("profile");


    // The Initial Data
    const initialData = {
        credentialID    : 0,
        firstName       : "",
        lastName        : "",
        email           : "",
        phone           : "",
        newPassword     : "",
        confirmPassword : "",
        sendEmails      : 1,
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        await editElem(data);
        await fetchElem();
    };

    // The Form State
    const {
        loading, data, errors, setElem, handleChange, handleSubmit,
    } = useForm("settings", initialData, handleEdit);


    // Load the Data
    React.useEffect(() => {
        fetchElem();
    }, []);

    // Data Updated
    React.useEffect(() => {
        setElem(elem);
    }, [ edition ]);



    // Do the Render
    return <Form onSubmit={handleSubmit}>
        <Columns amount={isAnyAdmin ? "1" : "2"}>
            <InputField
                name="firstName"
                label="GENERAL_FIRST_NAME"
                value={data.firstName}
                error={errors.firstName}
                onChange={handleChange}
                isRequired
            />
            <InputField
                name="lastName"
                label="GENERAL_LAST_NAME"
                value={data.lastName}
                error={errors.lastName}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="email"
                name="email"
                label="GENERAL_EMAIL"
                value={data.email}
                error={errors.email}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="tel"
                name="phone"
                label="GENERAL_CELLPHONE"
                value={data.phone}
                error={errors.phone}
                onChange={handleChange}
            />
        </Columns>

        <InputField
            type="password"
            name="newPassword"
            label="GENERAL_NEW_PASSWORD"
            value={data.newPassword}
            error={errors.newPassword}
            onChange={handleChange}
            autoComplete="new-password"
        />
        <InputField
            type="password"
            name="confirmPassword"
            label="GENERAL_PASSWORD_CONFIRM"
            value={data.confirmPassword}
            error={errors.confirmPassword}
            onChange={handleChange}
            autoComplete="new-password"
        />

        <InputField
            type="toggle"
            name="sendEmails"
            label="GENERAL_SEND_EMAILS"
            value={data.sendEmails}
            onChange={handleChange}
            withBorder
        />

        <Footer>
            <Button
                variant="primary"
                message="GENERAL_SAVE"
                onClick={handleSubmit}
                isDisabled={loading}
            />
        </Footer>
    </Form>;
}

export default SettingsForm;
