import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import IconLink             from "Dashboard/Components/Link/IconLink";
import Menu                 from "Dashboard/Components/Menu/Menu";
import MenuItem             from "Dashboard/Components/Menu/MenuItem";



// Styles
const ActionIcon = Styled(IconLink)`
    display: none;
    background-color: var(--light-gray);
`;



/**
 * The Chat Action
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChatAction(props) {
    const { canEdit, recipeCount, reqCoverage, hasCoverage, file, onAction } = props;


    // The References
    const targetRef = React.useRef(null);

    // The Current State
    const [ showAction, setShowAction ] = React.useState(false);


    // Handles the Action
    const handleAction = (type, index) => {
        onAction(type, index, file);
        setShowAction(false);
    };

    // The Menu Items
    const items = React.useMemo(() => {
        const result = [];
        result.push(<MenuItem
            key="recipe"
            icon="add"
            message="DASHBOARD_PROCESS_ADD_RECEIPT"
            onClick={() => handleAction("recipe", 0)}
            isSmall
        />);
        for (let i = 1; i <= recipeCount; ++i) {
            result.push(<MenuItem
                key={`recipe-${i}`}
                icon="edit"
                message={NLS.format("DASHBOARD_PROCESS_REPLACE_RECEIPT", String(i))}
                onClick={() => handleAction("recipe", i)}
                isSmall
            />);
        }
        if (reqCoverage) {
            result.push(<MenuItem
                key="coverage"
                icon="attachment"
                message={hasCoverage ? "DASHBOARD_PROCESS_REPLACE_COVERAGE" : "DASHBOARD_PROCESS_ADD_COVERAGE"}
                onClick={() => handleAction("coverage", 0)}
                isSmall
            />);
        }
        return result;
    }, [ recipeCount, reqCoverage, hasCoverage ]);


    // Do the Render
    if (!canEdit) {
        return <React.Fragment />;
    }
    return <>
        <ActionIcon
            passedRef={targetRef}
            className="bubble-action"
            variant="black"
            icon="edit"
            tooltip="DASHBOARD_PROCESS_UPDATE_FILE"
            onClick={() => setShowAction(true)}
            isSmall
        />

        <Menu
            open={showAction}
            targetRef={targetRef}
            direction="bottom-left"
            gap={4}
            onClose={() => setShowAction(false)}
        >
            {items}
        </Menu>
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChatAction.propTypes = {
    canEdit     : PropTypes.bool.isRequired,
    recipeCount : PropTypes.number.isRequired,
    reqCoverage : PropTypes.bool.isRequired,
    hasCoverage : PropTypes.bool.isRequired,
    file        : PropTypes.string.isRequired,
    onAction    : PropTypes.func,
};

export default ChatAction;
