import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { ChangeCatalog }    from "Utils/API";



// The initial State
const initialState = {
    loading     : true,
    error       : false,
    edition     : 0,
    canCreate   : false,
    canEdit     : false,
    canValidate : false,
    canFilter   : false,
    canImport   : false,
    canExport   : false,
    list        : [],
    total       : 0,
    elem        : {},
    changes     : [],
    filters     : {
        search   : "",
        fromDate : "",
        toDate   : "",
    },
    sort        : {
        filter   : "",
        orderBy  : "createdTime",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "CHANGE_CATALOG_LOADING" });
    },

    /**
     * Fetches the Change Catalogs
     * @param {Function} dispatch
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        const data = await ChangeCatalog.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "CHANGE_CATALOG_LIST", data });
    },

    /**
     * Fetches a single Change Catalog
     * @param {Function} dispatch
     * @param {Number}   changeCatalogID
     * @returns {Promise}
     */
    async fetchElem(dispatch, changeCatalogID) {
        const data = await ChangeCatalog.getOne({ changeCatalogID });
        dispatch({ type : "CHANGE_CATALOG_ELEM", data });
    },

    /**
     * Starts Using a Change Catalog
     * @param {Function} dispatch
     * @param {Number}   changeCatalogID
     * @returns {Promise}
     */
    startUsingElem(dispatch, changeCatalogID) {
        return ChangeCatalog.startUsing({ changeCatalogID });
    },

    /**
     * Stops Using a Change Catalog
     * @param {Function} dispatch
     * @param {Number}   changeCatalogID
     * @returns {Promise}
     */
    stopUsingElem(dispatch, changeCatalogID) {
        return ChangeCatalog.stopUsing({ changeCatalogID });
    },

    /**
     * Processes a Change Catalog
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    processElem(dispatch, data) {
        return ChangeCatalog.process(data);
    },

    /**
     * Validates a Change Catalog
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    validateElem(dispatch, data) {
        return ChangeCatalog.validate(data);
    },

    /**
     * Validates multiple Change Catalogs
     * @param {Function}          dispatch
     * @param {(Number[]|Number)} changeCatalogID
     * @returns {Promise}
     */
    multiValidateElem(dispatch, changeCatalogID) {
        const changeCatalogIDs = JSON.stringify(Utils.toArray(changeCatalogID));
        return ChangeCatalog.multiValidate({ changeCatalogIDs });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate          = DateTime.formatDate(elem.createdTime,  "dashesSeconds");
    elem.processDate          = DateTime.formatDate(elem.processTime,  "dashesSeconds");
    elem.validateDate         = DateTime.formatDate(elem.validateTime, "dashesSeconds");
    elem.usingCircle          = elem.isUsing ? "red" : "green";
    elem.moduleText           = NLS.get("SELECT_MODULES", elem.module);
    elem.modificationTypeText = NLS.get("SELECT_MODIFICATION_TYPES", elem.modificationType);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "CHANGE_CATALOG_LIST", "CHANGE_CATALOG_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "CHANGE_CATALOG_LOADING":
        return {
            ...state,
            loading     : true,
        };

    case "CHANGE_CATALOG_LIST":
        return {
            ...state,
            loading     : false,
            error       : false,
            canCreate   : action.data.canCreate,
            canEdit     : action.data.canEdit,
            canValidate : action.data.canValidate,
            canFilter   : action.data.canFilter,
            canImport   : action.data.canImport,
            canExport   : action.data.canExport,
            list        : Utils.parseList(action.data.list, parseElem),
            total       : action.data.total,
            filters     : action.data.filters,
            sort        : action.data.sort,
        };

    case "CHANGE_CATALOG_ELEM":
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            elem        : parseElem(action.data.elem),
            changes     : Utils.parseList(action.data.changes, parseElem),
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
