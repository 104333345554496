import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Commons              from "Utils/Commons";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Container = Styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--main-gap);
    padding: var(--main-gap);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
`;

const Title = Styled.h3`
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
`;

const Total = Styled.div.attrs(({ color }) => ({ color }))`
    font-size: 24px;
    white-space: nowrap;
    ${(props) => props.color && `color: ${props.color};`}
`;



/**
 * The Dashboard Total
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardTotal(props) {
    const { isHidden, message, value, isPrice, isDiff } = props;


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <Container>
        <Title>{NLS.get(message)}</Title>
        <Total color={isDiff ? (value > 0 ? "green" : "red") : ""}>
            {isDiff && <Icon icon={value > 0 ? "up" : "down"} />}
            {Commons.formatNumber(value, isPrice)}
        </Total>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardTotal.propTypes = {
    isHidden : PropTypes.bool,
    message  : PropTypes.string.isRequired,
    value    : PropTypes.number.isRequired,
    isPrice  : PropTypes.bool,
    isDiff   : PropTypes.bool,
};

export default DashboardTotal;
