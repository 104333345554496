import React                from "react";

// Components
import CatalogHeader        from "./Catalog/CatalogHeader";
import CatalogContent       from "./Catalog/CatalogContent";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";



/**
 * The Dashboard Catalog Page
 * @returns {React.ReactElement}
 */
function DashboardCatalogPage() {

    // Do the Render
    return <Main>
        <Header message="DASHBOARD_NAME" icon="home">
            <CatalogHeader />
        </Header>

        <Content>
            <CatalogContent />
        </Content>
    </Main>;
}

export default DashboardCatalogPage;
