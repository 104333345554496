import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// The APIs
import {
    AdministrationRoute,
} from "Utils/API";



// The initial State
const initialState = {
    loading    : true,
    error      : false,
    edition    : 0,
    canCreate  : false,
    canEdit    : false,
    canFilter  : false,
    canImport  : false,
    canExport  : false,
    list       : [],
    total      : 0,
    elem       : {},
    filters    : {},
    hasFilters : false,
    sort       : {
        orderBy  : "alfabetaID",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "ADMIN_ROUTE_LOADING" });
    },

    /**
     * Fetches the Administration Route List
     * @param {Function} dispatch
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        const data   = await AdministrationRoute.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "ADMIN_ROUTE_LIST", data });
    },

    /**
     * Fetches a single Administration Route
     * @param {Function} dispatch
     * @param {Number}   administrationRouteID
     * @returns {Promise}
     */
    async fetchElem(dispatch, administrationRouteID) {
        const data = await AdministrationRoute.getOne({ administrationRouteID });
        dispatch({ type : "ADMIN_ROUTE_ELEM", data });
    },

    /**
     * Edits/Creates a Administration Route
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return AdministrationRoute.edit(data);
    },

    /**
     * Deletes a Administration Route
     * @param {Function} dispatch
     * @param {Number}   administrationRouteID
     * @returns {Promise}
     */
    deleteElem(dispatch, administrationRouteID) {
        return AdministrationRoute.delete({ administrationRouteID });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "ADMIN_ROUTE_LIST", "ADMIN_ROUTE_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "ADMIN_ROUTE_LOADING":
        return {
            ...state,
            loading    : true,
        };

    case "ADMIN_ROUTE_LIST":
        return {
            ...state,
            loading    : false,
            error      : false,
            canCreate  : action.data.canCreate,
            canEdit    : action.data.canEdit,
            canFilter  : action.data.canFilter,
            canImport  : action.data.canImport,
            canExport  : action.data.canExport,
            list       : action.data.list,
            total      : action.data.total,
            filters    : action.data.filters,
            hasFilters : !Utils.isEmpty(action.data.filters),
            sort       : action.data.sort,
        };

    case "ADMIN_ROUTE_ELEM":
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            elem       : action.data.elem,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
