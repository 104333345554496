import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import OperationsTotals     from "./Components/OperationsTotals";
import OperationsTimes      from "./Components/OperationsTimes";
import OperationsRejects    from "./Components/OperationsRejects";
import OperationsCancels    from "./Components/OperationsCancels";
import OperationsRetirement from "./Components/OperationsRetirement";
import HeatMapChart         from "../Utils/Charts/HeatMapChart";
import DashboardList        from "../Utils/Components/DashboardList";

// Dashboard
import Columns              from "Dashboard/Components/Form/Columns";




// Styles
const Container = Styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--main-gap);
`;



/**
 * The Operations Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function OperationsContent(props) {
    const { isHidden } = props;

    const {
        filters, stateTotals,
        createdHeatmap, approvedHeatmap, paidHeatmap,
        retireHeatmap, retiredHeatmap, deliverHeatmap,
        deliveredHeatmap, rejectedHeatmap, canceledHeatmap,
        retirementAllTotals, retirementTotals,
        retirementSubsidiaries, retirementPharmacies,
    } = Store.useState("dashboardOperations");

    const { fetchList } = Store.useAction("dashboardOperations");


    // Variables
    const heatMaps = {
        "DASHBOARD_HEAT_MAP_CREATION"  : createdHeatmap,
        "DASHBOARD_HEAT_MAP_APPROVED"  : approvedHeatmap,
        "DASHBOARD_HEAT_MAP_PAID"      : paidHeatmap,
        "DASHBOARD_HEAT_MAP_RETIRE"    : retireHeatmap,
        "DASHBOARD_HEAT_MAP_RETIRED"   : retiredHeatmap,
        "DASHBOARD_HEAT_MAP_DELIVER"   : deliverHeatmap,
        "DASHBOARD_HEAT_MAP_DELIVERED" : deliveredHeatmap,
        "DASHBOARD_HEAT_MAP_REJECTED"  : rejectedHeatmap,
        "DASHBOARD_HEAT_MAP_CANCELED"  : canceledHeatmap,
    };


    // Initial Fetch
    React.useEffect(() => {
        if (!isHidden) {
            fetchList(filters);
        }
    }, [ isHidden ]);


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <Container>
        <OperationsTotals />

        <Columns amount="5">
            {stateTotals.map(({ name, list }) => <DashboardList
                key={name}
                title="DASHBOARD_STATES_TITLE"
                column="SUBSIDIARIES_SINGULAR"
                name={name}
                list={list}
            />)}
        </Columns>

        <OperationsTimes />
        <OperationsRejects />
        <OperationsCancels />

        <Columns amount="2" singleWidth="1200">
            {Object.entries(heatMaps).map(([ title, data ]) => <HeatMapChart
                key={title}
                title={title}
                data={data}
            />)}
        </Columns>

        <OperationsRetirement
            title="DASHBOARD_RETIREMENTS_TOTAL_TITLE"
            data={retirementAllTotals}
        />
        <OperationsRetirement
            title="DASHBOARD_RETIREMENTS_EFFECTIVE_TITLE"
            data={retirementTotals}
        />

        <Columns>
            <>
                {retirementSubsidiaries.map(({ name, list }) => <DashboardList
                    key={name}
                    title="DASHBOARD_RETIREMENTS_SUBSIDIARY_TITLE"
                    column="SUBSIDIARIES_SINGULAR"
                    name={name}
                    list={list}
                />)}
                {retirementPharmacies.map(({ name, list }) => <DashboardList
                    key={name}
                    title="DASHBOARD_RETIREMENTS_PHARMACY_TITLE"
                    column="PHARMACIES_SINGULAR"
                    name={name}
                    list={list}
                />)}
            </>
        </Columns>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
OperationsContent.propTypes = {
    isHidden : PropTypes.bool.isRequired,
};

export default OperationsContent;
