import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { Objective }        from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canFilter : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    sort      : {
        orderBy  : "time",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "OBJECTIVE_LOADING" });
    },

    /**
     * Fetches the Objective List
     * @param {Function} dispatch
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, params = {}) {
        const data = await Objective.getAll(params);
        data.sort = params;
        dispatch({ type : "OBJECTIVE_LIST", data });
    },

    /**
     * Fetches a single Objective
     * @param {Function} dispatch
     * @param {Number}   objectiveID
     * @returns {Promise}
     */
    async fetchElem(dispatch, objectiveID) {
        const data = await Objective.getOne({ objectiveID });
        dispatch({ type : "OBJECTIVE_ELEM", data });
    },

    /**
     * Edits/Creates a Objective
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return Objective.edit(data);
    },

    /**
     * Deletes a Objective
     * @param {Function} dispatch
     * @param {Number}   objectiveID
     * @returns {Promise}
     */
    deleteElem(dispatch, objectiveID) {
        return Objective.delete({ objectiveID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.monthName = NLS.get("DATE_MONTH_NAMES", elem.month);
    elem.name      = `${elem.monthName} ${elem.year}`;
    elem.grossText = `$ ${elem.grossTotal}`;
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "OBJECTIVE_LIST", "OBJECTIVE_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "OBJECTIVE_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "OBJECTIVE_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canFilter : action.data.canFilter,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
            sort      : action.data.sort,
        };

    case "OBJECTIVE_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : parseElem(action.data.elem),
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
