import React                from "react";
import PropTypes            from "prop-types";
import { Bar }              from "react-chartjs-2";
import NLS                  from "Dashboard/Core/NLS";
import DateTime             from "Dashboard/Utils/DateTime";
import Commons              from "Utils/Commons";

// Components
import DashboardReport      from "../Components/DashboardReport";



/**
 * The Heat Map Chart
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function HeatMapChart(props) {
    const { title, data } = props;


    // Generates the Labels
    const labels = [];
    for (let hour = 0; hour <= 23; hour++) {
        labels.push(`${hour} hs`);
    }


    // Creates the Datasets
    const createDatasets = React.useCallback(() => {
        let maxValue = 0;
        for (const value of Object.values(data)) {
            maxValue = Math.max(value, maxValue);
        }

        const result = [];
        for (let day = 6; day >= 0; day--) {
            const dataset = {
                label              : DateTime.dayToName((day + 1) % 7),
                data               : [],
                backgroundColor    : [],
                borderColor        : "#ffffff00",
                hoverBorderColor   : "#ffffff00",
                borderWidth        : 2,
                hoverBorderWidth   : 2,
                barPercentage      : 1,
                categoryPercentage : 1,
            };
            for (let hour = 0; hour <= 23; hour++) {
                const key   = `${day}-${hour}`;
                let   alpha = 0;
                if (data[key]) {
                    alpha = data[key] / maxValue;
                }
                dataset.data.push(1);
                dataset.backgroundColor.push(`rgba(2, 131, 200, ${alpha})`);
            }
            result.push(dataset);
        }

        return result;
    }, [ JSON.stringify(data) ]);


    // Do the Render
    return <DashboardReport message={title}>
        <Bar
            options={{
                maintainAspectRatio : false,
                scales  : {
                    y : {
                        stacked : true,
                        min     : 0,
                        ticks   : {
                            precision   : 0,
                            padding     : 0,
                            labelOffset : -15,
                            callback(label) {
                                if (Number(label) >= 7) {
                                    return "";
                                }
                                return DateTime.dayToName((7 - Number(label)) % 7).substring(0, 3);
                            },
                        },
                    },
                    x : {
                        stacked : true,
                    },
                },
                plugins : {
                    legend  : { display : false },
                    tooltip : Commons.getTooltipOptions(false, {
                        title(context) {
                            return context[0].dataset.label + ", " + context[0].label;
                        },
                        label(context) {
                            const datasetIndex = 6 - context.datasetIndex;
                            const amount       = data[`${datasetIndex}-${context.dataIndex}`] ?? 0;
                            return `${NLS.get("ORDERS_NAME")}: ${amount}`;
                        },
                    }, {
                        xAlign : "center",
                        yAlign : "center",
                    }),
                },
            }}
            data={{
                labels   : labels,
                datasets : createDatasets(),
            }}
        />
    </DashboardReport>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
HeatMapChart.propTypes = {
    title : PropTypes.string.isRequired,
    data  : PropTypes.object.isRequired,
};

export default HeatMapChart;
