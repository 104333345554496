import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Commons              from "Utils/Commons";
import { Product }          from "Utils/API";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";
import Icon                 from "Dashboard/Components/Common/Icon";
import PromptDialog         from "Dashboard/Components/Dialogs/PromptDialog";



// Styles
const Products = Styled.section`
    grid-area: products;
    position: relative;

    .inputfield-label {
        left: 8px;
        padding: 0;
        text-overflow: clip;
    }
    .input-content {
        padding-left: 8px;
        padding-right: 8px;
    }

    .inputfield-toggle .input-content {
        padding-left: 4px;
        font-size: 12px;
    }
    .inputfield-toggle .input-content label span {
        margin-right: 4px;
    }

    .inputfield-select .input-content {
        gap: 0;
    }
`;

const Title = Styled.h3`
    margin: 0 0 8px 8px;
    color: var(--alternative-color);
    font-family: var(--title-font);
    font-size: 18px;
`;

const Help = Styled(Icon)`
    position: absolute;
    top: 1px;
    right: 1px;
    font-size: 18px;
    color: var(--primary-color);
    z-index: 2;
`;



/**
 * The Process View Products
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProcessProducts(props) {
    const { unitPrices, prices, subTotals, data, errors, onChange } = props;

    const { elem, tooltips, saleTypes, canWaitOrder } = Store.useState("dashboardProcess");


    // The Current State
    const [ showVoucher, setShowVoucher ] = React.useState(false);
    const [ voucherDate, setVoucherDate ] = React.useState("");
    const [ index,       setIndex       ] = React.useState(-1);


    // Handles the Price Change
    const setPrice = (index, name, newValue, id, newID, data) => {
        unitPrices.current[index] = data.price;
    };

    // Returns the after Title text
    const getAfterTitle = (elem, index) => {
        if (!elem.voucherDate) {
            return "";
        }

        const date = DateTime.formatDate(elem.voucherDate, "dashes");
        return NLS.format("DASHBOARD_PROCESS_VOUCHER_DATE", date);
    };

    // Handles the Voucher Open
    const handleVoucherOpen = (index) => {
        setShowVoucher(true);
        setIndex(index);
        const products = JSON.parse(data.products);
        setVoucherDate(products[index].voucherDate ?? "");
    };

    // Handles the Voucher Set
    const handleVoucherSet = (date) => {
        setShowVoucher(false);
        const products = JSON.parse(data.products);
        products[index].voucherDate = date;
        onChange("products", JSON.stringify(products));
    };

    // Handles the Voucher Close
    const handleVoucherClose = () => {
        setShowVoucher(false);
        setIndex(-1);
        setVoucherDate("");
    };


    // Variables
    const productTooltip  = Commons.getTooltip(tooltips, "Product");
    const discountTooltip = Commons.getTooltip(tooltips, "Discount");


    // Do the Render
    return <>
        <Products>
            <Title>{NLS.get("PRODUCTS_NAME")}</Title>
            <InputField
                type="fields"
                name="products"
                title="PRODUCTS_TITLE"
                getAfterTitle={getAfterTitle}
                addButton="PRODUCTS_ADD_PRODUCT"
                columns="16"
                value={data.products}
                errors={errors}
                onChange={onChange}
                extraIcon={canWaitOrder ? "voucher" : ""}
                onExtraIcon={handleVoucherOpen}
                noneText="PRODUCTS_NONE_AVAILABLE"
                isDisabled={!elem.canEditOrder}
                allowEmpty
            >
                <InputItem
                    columns="3"
                    type="toggle"
                    name="isNew"
                    label="ORDERS_NEW_PRODUCT"
                    withBorder
                />
                <InputItem
                    hide={(elem) => !!elem.isNew}
                    columns="8"
                    type="suggest"
                    name="name"
                    label="PRODUCTS_SINGULAR"
                    suggestID="productID"
                    suggestFetch={Product.search}
                    suggestWidth={400}
                    onChange={setPrice}
                    hideClear
                    component={<Help
                        isHidden={!productTooltip}
                        icon="help"
                        tooltip={productTooltip}
                        tooltipWidth={300}
                    />}
                />
                <InputItem
                    hide={(elem) => !elem.isNew}
                    columns="5"
                    name="product"
                    label="PRODUCTS_SINGULAR"
                    component={<Help
                        isHidden={!productTooltip}
                        icon="help"
                        tooltip={productTooltip}
                        tooltipWidth={300}
                    />}
                />
                <InputItem
                    hide={(elem) => !elem.isNew}
                    columns="3"
                    type="select"
                    name="saleTypeID"
                    label="SALE_TYPES_SINGULAR"
                    options={saleTypes}
                    shrinkLabel
                    hideClear
                />
                <InputItem
                    columns="2"
                    type="number"
                    name="quantity"
                    label="GENERAL_QUANTITY"
                    value="1"
                    minValue={0}
                    shrinkLabel
                />
                <InputItem
                    columns="3"
                    type="number"
                    name="price"
                    label="GENERAL_PRICE"
                    icon="price"
                    getType={(elem) => elem.isNew ? "number" : "text"}
                    getValue={(index, elem) => elem.isNew ? elem.price : (prices.current[index] ?? 0)}
                    getDisabled={(elem) => !elem.isNew}
                    shrinkLabel
                />

                <InputItem
                    getColumns={(elem) => elem.discountType === "none" ? "13" : "3"}
                    type="select"
                    name="discountType"
                    label="ORDERS_DISCOUNT"
                    options="SELECT_DISCOUNT_TYPES"
                    value="none"
                    component={<Help
                        isHidden={!discountTooltip}
                        icon="help"
                        tooltip={discountTooltip}
                        tooltipWidth={300}
                    />}
                    shrinkLabel
                    hideClear
                />
                <InputItem
                    hide={(elem) => elem.discountType === "none"}
                    columns="5"
                    type="number"
                    name="discountValue"
                    label="ORDERS_DISCOUNT_VALUE"
                    minValue={0}
                    step={0.01}
                    shrinkLabel
                    getIcon={(elem) => elem.discountType === "percent" ? "" : "price"}
                    getPostIcon={(elem) => elem.discountType === "percent" ? "percent" : ""}
                />
                <InputItem
                    hide={(elem) => elem.discountType === "none"}
                    columns="5"
                    type="select"
                    name="discountOrigin"
                    label="ORDERS_DISCOUNT_ORIGIN"
                    options="SELECT_DISCOUNT_ORIGINS"
                    hideClear
                />
                <InputItem
                    columns="3"
                    type="text"
                    name="subTotal"
                    label="GENERAL_SUBTOTAL"
                    icon="price"
                    getValue={(index) => subTotals.current[index] ?? 0}
                    shrinkLabel
                    isDisabled
                />
            </InputField>
        </Products>

        <PromptDialog
            open={showVoucher}
            icon="voucher"
            title="DASHBOARD_PROCESS_VOUCHER_TITLE"
            message="DASHBOARD_PROCESS_VOUCHER_TEXT"
            content={index + 1}
            initialValue={voucherDate}
            inputType="date"
            onSubmit={handleVoucherSet}
            onClose={handleVoucherClose}
            isOptional
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProcessProducts.propTypes = {
    unitPrices : PropTypes.object.isRequired,
    prices     : PropTypes.object.isRequired,
    subTotals  : PropTypes.object.isRequired,
    data       : PropTypes.object.isRequired,
    errors     : PropTypes.object.isRequired,
    onChange   : PropTypes.func.isRequired,
};

export default ProcessProducts;
