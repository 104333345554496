import React                from "react";
import Styled               from "styled-components";
import PropTypes            from "prop-types";

// Dashboard
import Dialog               from "Dashboard/Components/Dialog/Dialog";
import DialogHeader         from "Dashboard/Components/Dialog/DialogHeader";
import DialogBody           from "Dashboard/Components/Dialog/DialogBody";
import DialogFooter         from "Dashboard/Components/Dialog/DialogFooter";
import PDFViewer            from "Dashboard/Components/Media/PDFViewer";
import PDFNavigation        from "Dashboard/Components/Media/PDFNavigation";
import Video                from "Dashboard/Components/Common/Video";
import MultiLine            from "Dashboard/Components/Common/MultiLine";
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Image = Styled.div`
    display: flex;
    justify-content: center;
    img {
        max-width: 100%;
    }
`;

const Text = Styled(MultiLine)`
    color: var(--black-color);
    margin-top: 32px;
`;



/**
 * The Process File
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProcessFile(props) {
    const { open, title, isPDF, isImage, isVideo, fileUrl, text, onClose } = props;

    // The Current State
    const [ currentPage, setCurrentPage ] = React.useState(1);
    const [ totalPages,  setTotalPages  ] = React.useState(0);


    // Do the Render
    return <Dialog
        open={open}
        onClose={onClose}
        width={900}
    >
        <DialogHeader
            message={title || "DASHBOARD_PROCESS_FILE_TITLE"}
            icon="view"
        />

        <DialogBody withSpacing>
            {isPDF && <PDFViewer
                source={fileUrl}
                currentPage={currentPage}
                onLoad={setTotalPages}
            />}
            {isImage && <Image>
                <img src={fileUrl} />
            </Image>}
            {isVideo && <Video
                title="Video"
                source={fileUrl}
            />}
            {!!text && <Text>{text}</Text>}
        </DialogBody>

        <DialogFooter cancel="GENERAL_CLOSE">
            <Button
                variant="primary"
                href={fileUrl}
                target="_blank"
                message="GENERAL_DOWNLOAD"
            />
            {isPDF && <PDFNavigation
                currentPage={currentPage}
                totalPages={totalPages}
                onChange={setCurrentPage}
            />}
        </DialogFooter>
    </Dialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProcessFile.propTypes = {
    open    : PropTypes.bool.isRequired,
    title   : PropTypes.string,
    isPDF   : PropTypes.bool,
    isImage : PropTypes.bool,
    isVideo : PropTypes.bool,
    fileUrl : PropTypes.string.isRequired,
    text    : PropTypes.string,
    onClose : PropTypes.func.isRequired,
};

export default ProcessFile;
