import React                from "react";
import Store                from "Dashboard/Core/Store";
import Commons              from "Utils/Commons";

// Components
import BarLineChart         from "../../Utils/Charts/BarLineChart";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import ColorCircle          from "Dashboard/Components/Common/ColorCircle";



/**
 * The Commercial Coverage Types
 * @returns {React.ReactElement}
 */
function CommercialCoverageTypes() {
    const { coverageTypeTotals } = Store.useState("dashboardCommercial");


    // Do the Render
    return <BarLineChart
        title="DASHBOARD_COVERAGE_TYPE_TITLE"
        fieldBar="billingTotal"
        fieldLine="effectiveUnits"
        data={coverageTypeTotals}
    >
        <Table noSorting notFixed rightSpace>
            <TableHead>
                <TableHeader field="name"           message="COVERAGE_TYPES_SINGULAR" isFlex isTitle />
                <TableHeader field="effectiveUnits" message="DASHBOARD_UNITS"         isFlex maxWidth="80" align="center" />
                <TableHeader field="billingTotal"   message="DASHBOARD_GROSS_BILLING" isFlex maxWidth="120" align="right" rightSpace />
            </TableHead>
            <TableBody>
                {coverageTypeTotals.map((elem, index) => <TableRow key={elem.name}>
                    <TableCell>
                        <ColorCircle color={Commons.getColor(index)} />
                        {elem.name}
                    </TableCell>
                    <TableCell message={elem.effectiveUnits} />
                    <TableCell message={Commons.formatNumber(elem.billingTotal, true)} />
                </TableRow>)}
            </TableBody>
        </Table>
    </BarLineChart>;
}

export default CommercialCoverageTypes;
