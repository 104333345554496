import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Macro Category Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MacroCategoryEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { editElem } = Store.useAction("macroCategory");


    // The Initial Data
    const initialData = {
        macroCategoryID : 0,
        name            : "",
        position        : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("macroCategory", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("macroCategory", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="macro-category"
        title={elemID ? "MACRO_CATEGORIES_EDIT_TITLE" : "MACRO_CATEGORIES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MacroCategoryEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default MacroCategoryEdit;
