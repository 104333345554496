import React                from "react";
import Store                from "Dashboard/Core/Store";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import PageContainer        from "Dashboard/Components/Page/PageContainer";
import PageAccordion        from "Dashboard/Components/Page/PageAccordion";
import AccordionItem        from "Dashboard/Components/Accordion/AccordionItem";
import InputField           from "Dashboard/Components/Form/InputField";
import ViewField            from "Dashboard/Components/Form/ViewField";
import Button               from "Dashboard/Components/Form/Button";
import Alert                from "Dashboard/Components/Form/Alert";



/**
 * The Gateway Page User
 * @returns {React.ReactElement}
 */
function GatewayPageUser() {
    const { charging, edition, content, gateways, mpUrl } = Store.useState("subsidiaryGateway");
    const { fetchAll, editElem } = Store.useAction("subsidiaryGateway");


    // The Current Data
    const [ selected, setSelected ] = React.useState("");

    // Load the Data
    React.useEffect(() => {
        fetchAll();
    }, []);

    // Store the Data
    React.useEffect(() => {
        if (gateways.length && !selected) {
            setSelected(gateways[0].gatewayCode);
        }
    }, [ edition ]);

    // Handles the Edit
    const handleEdit = async (gatewayCode, isActive) => {
        const result = await editElem({ gatewayCode, isActive });
        if (result.success) {
            fetchAll();
        }
    };


    // Do the Render
    return <Main>
        <Header message="GATEWAYS_NAME" icon="gateway" />
        <Content isLoading={charging}>
            <PageContainer>
                <PageAccordion selected={selected} onChange={setSelected}>
                    {gateways.map((elem) => {
                        const item = content[elem.gatewayCode] ?? {};

                        return <AccordionItem
                            key={elem.gatewayCode}
                            value={elem.gatewayCode}
                            message={elem.name}
                            maxWidth={600}
                            withGap
                        >
                            <InputField
                                type="toggle"
                                name="isActive"
                                label="GENERAL_IS_ACTIVE_FEM"
                                value={item.isActive || 0}
                                onChange={(name, value) => handleEdit(elem.gatewayCode, value)}
                                withBorder
                            />

                            {elem.hasLink && !item.isLinked && <>
                                <ViewField
                                    label="GATEWAYS_LINK_BUTTON"
                                    message="GATEWAYS_LINK_ACCOUNT"
                                />
                                <Button
                                    variant="primary"
                                    message="GATEWAYS_LINK_BUTTON"
                                    href={mpUrl}
                                />
                                <Alert
                                    variant="warning"
                                    message="GATEWAYS_LINK_ALERT"
                                />
                            </>}
                            {elem.hasLink && item.isLinked && <>
                                <ViewField
                                    label="GATEWAYS_USER_ID"
                                    value={content.MercadoPago?.providerID}
                                    showEmpty
                                />
                            </>}
                        </AccordionItem>;
                    })}
                </PageAccordion>
            </PageContainer>
        </Content>
    </Main>;
}

export default GatewayPageUser;
