import React                from "react";
import PropTypes            from "prop-types";
import MediaType            from "Dashboard/Core/MediaType";

// Components
import MediaDialog          from "Components/App/Setup/Media/MediaDialog";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Media Field
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MediaField(props) {
    const {
        isHidden, name, label, helperText, value, isRequired,
        mediaPath, mediaType, onChange,
    } = props;

    // The Current State
    const [ showDialog, setShowDialog ] = React.useState(false);


    // Handles the Dialog Submit
    const handleSubmit = (value) => {
        onChange(name, value);
        closeDialog();
    };

    // Opens the Dialog
    const openDialog = (e) => {
        setShowDialog(true);
        e.preventDefault();
    };

    // Closes the Dialog
    const closeDialog = () => {
        setShowDialog(false);
    };


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <>
        <InputField
            {...props}
            type="media"
            label={label}
            onMedia={openDialog}
            helperText={helperText}
            onlyImages={MediaType.onlyImages(mediaType)}
            withLabel
        />
        <MediaDialog
            open={showDialog}
            mediaType={mediaType}
            path={mediaPath}
            initialSelect={value}
            isRequired={isRequired}
            onSubmit={handleSubmit}
            onClose={closeDialog}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MediaField.propTypes = {
    isHidden    : PropTypes.bool,
    className   : PropTypes.string,
    name        : PropTypes.string,
    label       : PropTypes.string,
    placeholder : PropTypes.string,
    value       : PropTypes.any,
    isRequired  : PropTypes.bool,
    isDisabled  : PropTypes.bool,
    onChange    : PropTypes.func.isRequired,
    mediaPath   : PropTypes.string,
    mediaType   : PropTypes.string,
    error       : PropTypes.string,
    helperText  : PropTypes.string,
    tabIndex    : PropTypes.string,
    fullWidth   : PropTypes.bool,
    autoFocus   : PropTypes.bool,
};

/**
 * The Default Properties
 * @typedef {Object} defaultProps
 */
MediaField.defaultProps = {
    className  : "",
    label      : "MEDIA_SINGULAR",
    isRequired : false,
    fullWidth  : false,
    autoFocus  : false,
};

export default MediaField;
