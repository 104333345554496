import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import KeyCode              from "Dashboard/Utils/KeyCode";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import ChatContent          from "Components/Utils/Chat/ChatContent";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import IconLink             from "Dashboard/Components/Link/IconLink";
import CircularLoader       from "Dashboard/Components/Loader/CircularLoader";



// Styles
const Container = Styled.aside`
    --input-border-color: var(--border-color-light);

    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    gap: var(--main-gap);
    width: var(--chat-width);
    height: var(--dialog-height);
    padding: 16px;
    margin-left: 16px;
    background-color: white;
    border-radius: var(--dialog-radius);
`;

const Chat = Styled.section`
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    gap: var(--main-gap);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
    padding-bottom: var(--main-gap);
    overflow: auto;
`;

const Reply = Styled.section`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const Textarea = Styled(InputField)`
    flex-grow: 2;
`;



/**
 * The Process View Chat
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProcessChat(props) {
    const { reqCoverageFile, onAction } = props;

    const { elem, chatItems, lastUpdate } = Store.useState("dashboardProcess");
    const { fetchMessages, sendMessage } = Store.useAction("dashboardProcess");


    // The References
    const timerRef     = React.useRef(null);
    const containerRef = React.useRef(null);

    // The Current State
    const [ update,      setUpdate      ] = React.useState(0);
    const [ firstScroll, setFirstScroll ] = React.useState(true);
    const [ atBottom,    setAtBottom    ] = React.useState(true);
    const [ message,     setMessage     ] = React.useState("");
    const [ sending,     setSending     ] = React.useState(false);


    // Add/Remove the Auto-update
    React.useEffect(() => {
        if (elem.hasActiveChat) {
            setAutoUpdate();
        } else {
            Utils.clearTimeout(timerRef);
        }
        return () => {
            if (elem.hasActiveChat) {
                Utils.clearTimeout(timerRef);
            }
        };
    }, [ elem.hasActiveChat ]);

    // Scroll container
    React.useEffect(() => {
        if (atBottom && containerRef.current) {
            window.setTimeout(() => scrollToBottom(firstScroll), 100);
            setFirstScroll(false);
        }
    }, [ lastUpdate ]);

    // Handle the Update
    React.useEffect(() => {
        fetchMessages(elem.orderID, lastUpdate);
        setAutoUpdate();
    }, [ update ]);


    // Sets an auto-update
    const setAutoUpdate = () => {
        Utils.setTimeout(timerRef, () => {
            setUpdate(update + 1);
        }, 5 * 1000);
    };

    // Handles the Scroll
    const handleScroll = async (e) => {
        let atBottom = false;
        if (e.target.scrollTop >= (e.target.scrollHeight - e.target.offsetHeight) - 50) {
            atBottom = true;
        }
        setAtBottom(atBottom);
    };

    // Scrolls to the Bottom of the Chat
    const scrollToBottom = (firstScroll) => {
        const node = containerRef.current;
        if (node) {
            node.scrollTo({
                top      : node.scrollHeight - node.offsetHeight,
                behavior : firstScroll ? "instant" : "smooth",
            });
        }
    };


    // Handles the Message KeyDown
    const handleKeyDown = (e) => {
        if (e.keyCode === KeyCode.DOM_VK_RETURN && !e.shiftKey) {
            handleSubmit();
            e.preventDefault();
        }
    };

    // Handles the submit
    const handleSubmit = async () => {
        if (message.length) {
            setSending(true);
            setMessage("");
            await sendMessage(elem.orderID, message, lastUpdate);
            setSending(false);
        }
    };


    // Do the Render
    return <Container>
        <Chat ref={containerRef} onScroll={handleScroll}>
            <ChatContent
                items={chatItems}
                canChat={elem.canChat}
                canEdit={elem.canEditOrder}
                recipeCount={elem.recipeFiles?.length || 0}
                reqCoverage={reqCoverageFile || elem.reqCoverage}
                hasCoverage={elem.hasCoverage}
                onAction={onAction}
            />
        </Chat>

        {elem.canChat && <Reply>
            <Textarea
                type="textarea"
                name="message"
                label="DASHBOARD_PROCESS_WRITE_TEXT"
                value={message}
                onChange={(name, value) => setMessage(value)}
                onKeyDown={handleKeyDown}
                maxLength={250}
                rows="1"
                maxRows="8"
            />
            <CircularLoader
                isHidden={!sending}
                isSmall
            />
            <IconLink
                isHidden={sending}
                icon="send"
                onClick={handleSubmit}
                isSmall
            />
        </Reply>}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProcessChat.propTypes = {
    reqCoverageFile : PropTypes.bool.isRequired,
    onAction        : PropTypes.func.isRequired,
};

export default ProcessChat;
