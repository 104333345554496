import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import SubCategoryFilter    from "./SubCategoryFilter";
import SubCategoryEdit      from "./SubCategoryEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Sub Category List
 * @returns {React.ReactElement}
 */
function SubCategoryList() {
    const { fetch, load, loadFilter } = useList("subCategory");

    const data = Store.useState("subCategory");
    const { loading, canEdit, list, total, filters, sort } = data;

    const { deleteElem } = Store.useAction("subCategory");


    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteElem(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "subCategoryID", elemID, "name");

    return <Main>
        <Header message="SUB_CATEGORIES_NAME" icon="sub-category">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="SUB_CATEGORIES_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="subCategoryID"     message="GENERAL_ID"                maxWidth="50" />
                    <TableHeader field="macroCategoryName" message="MACRO_CATEGORIES_SINGULAR" />
                    <TableHeader field="categoryName"      message="CATEGORIES_SINGULAR"       />
                    <TableHeader field="name"              message="SUB_CATEGORIES_SINGULAR"   isTitle />
                    <TableHeader field="productCount"      message="PRODUCTS_NAME"             maxWidth="90" align="center" />
                    <TableHeader field="mainPosition"      message="GENERAL_POSITION"          maxWidth="80" align="center" hideMobile />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.subCategoryID} elemID={elem.subCategoryID}>
                        <TableCell message={elem.subCategoryID}     />
                        <TableCell message={elem.macroCategoryName} />
                        <TableCell message={elem.categoryName}      />
                        <TableCell message={elem.name}              />
                        <TableCell message={elem.productCount}      />
                        <TableCell message={elem.mainPosition}      />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="SUB_CATEGORIES_EDIT_TITLE"   />
                    <TableAction action="DELETE" message="SUB_CATEGORIES_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <SubCategoryFilter
            open={action.isFilter}
            filters={filters}
            onSubmit={handleFilter}
            onClose={endAction}
        />
        <SubCategoryEdit
            open={action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="SUB_CATEGORIES_DELETE_TITLE"
            message="SUB_CATEGORIES_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

export default SubCategoryList;
