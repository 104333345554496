import React                from "react";
import PropTypes            from "prop-types";
import { PolarArea }        from "react-chartjs-2";
import Commons              from "Utils/Commons";

// Components
import DashboardContainer   from "../Components/DashboardContainer";
import DashboardReport      from "../Components/DashboardReport";
import DashboardTable       from "../Components/DashboardTable";



/**
 * The Dashboard PolarArea Chart
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PolarAreaChart(props) {
    const { title, field, data, noneMessage, children } = props;

    data.sort((a, b) => b[field] - a[field]);


    // Do the Render
    if (!data.length) {
        return <React.Fragment />;
    }
    return <DashboardContainer>
        <DashboardReport message={title}>
            <PolarArea
                options={{
                    maintainAspectRatio : false,
                    scales  : { r : { ticks : { precision : 0, z : 3 } } },
                    plugins : {
                        legend  : Commons.getLegendOptions("right"),
                        tooltip : Commons.getTooltipOptions(false),
                    },
                }}
                data={{
                    labels   : Commons.getLabelList(data, noneMessage, 0, 10),
                    datasets : [{
                        data            : Commons.getValueList(data, field, 10),
                        backgroundColor : Commons.getColorList(data, 10),
                    }],
                }}
            />
        </DashboardReport>

        <DashboardTable>
            {children}
        </DashboardTable>
    </DashboardContainer>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PolarAreaChart.propTypes = {
    title       : PropTypes.string.isRequired,
    noneMessage : PropTypes.string,
    field       : PropTypes.string.isRequired,
    data        : PropTypes.array.isRequired,
    children    : PropTypes.any,
};

export default PolarAreaChart;
