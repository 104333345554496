import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { DashboardDaily }   from "Utils/API";



// The initial State
const initialState = {
    loading       : true,
    error         : false,
    currentDate   : DateTime.create().toDayEnd(),
    totals        : { orders : -1 },
    thisPeriod    : {},
    lastPeriod    : {},
    dayLabels     : [],
    thisOrders    : [],
    thisEffective : [],
    thisBilling   : [],
    thisNet       : [],
    lastOrders    : [],
    lastEffective : [],
    lastBilling   : [],
    lastNet       : [],
    holidays      : [],
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "DASHBOARD_DAILY_LOADING" });
    },

    /**
     * Fetches the Daily Dashboard
     * @param {Function} dispatch
     * @param {Number}   currentTime
     * @returns {Promise}
     */
    async fetchList(dispatch, currentTime) {
        const data = await DashboardDaily.getAll({ currentTime });
        dispatch({ type : "DASHBOARD_DAILY_LIST", data });
    },

    /**
     * Sets the Current Date
     * @param {Function} dispatch
     * @param {DateTime} currentDate
     * @returns {Void}
     */
    setCurrentDate(dispatch, currentDate) {
        dispatch({ type : "DASHBOARD_DAILY_DATE", currentDate });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "DASHBOARD_DAILY_LIST")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "DASHBOARD_DAILY_LOADING":
        return {
            ...state,
            loading       : true,
        };

    case "DASHBOARD_DAILY_LIST":
        return {
            ...state,
            loading       : false,
            error         : false,
            totals        : action.data.totals,
            thisPeriod    : action.data.thisPeriod,
            lastPeriod    : action.data.lastPeriod,
            dayLabels     : action.data.dayLabels,
            thisOrders    : action.data.thisOrders,
            thisEffective : action.data.thisEffective,
            thisBilling   : action.data.thisBilling,
            thisNet       : action.data.thisNet,
            lastOrders    : action.data.lastOrders,
            lastEffective : action.data.lastEffective,
            lastBilling   : action.data.lastBilling,
            lastNet       : action.data.lastNet,
            holidays      : action.data.holidays,
        };

    case "DASHBOARD_DAILY_DATE":
        return {
            ...state,
            currentDate   : action.currentDate,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
