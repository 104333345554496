import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import Html                 from "Dashboard/Components/Common/Html";



// Styles
const Container = Styled.ul`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 0 0 0 24px;
    padding: 0;
    list-style: none;

    ::before {
        content: "";
        position: absolute;
        left: -20px;
        width: 2px;
        height: 100%;
        background-color: var(--alternative-color);
    }
`;

const Item = Styled.li.attrs(({ color }) => ({ color }))`
    --item-size: 14px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;

    ::before {
        content: "";
        position: absolute;
        box-sizing: border-box;
        top: 3px;
        left: calc(-19px - var(--item-size) / 2);
        width: var(--item-size);
        height: var(--item-size);
        background-color: ${(props) => props.color};
        box-shadow: 0 0 0 6px white;
        border-radius: 100%;
    }
`;

const State = Styled.div`
    font-size: 16px;
`;

const Date = Styled.div`
    font-weight: 200;
`;



/**
 * The Order State List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function OrderStateList(props) {
    const { states } = props;


    // Do the Render
    return <Container>
        {states.map((elem) => <Item
            key={elem.id}
            color={elem.color}
        >
            <State><b>{elem.name}</b></State>
            <Date>{elem.createdTimeDate}</Date>
            {!!elem.rejectReasonID && <Html>
                {NLS.format("ORDERS_STATES_REASON", elem.rejectReasonName)}
            </Html>}
            {!!elem.cancelReasonID && <Html>
                {NLS.format("ORDERS_STATES_REASON", elem.cancelReasonName)}
            </Html>}
            {!!elem.reason && <Html>
                {NLS.format("ORDERS_STATES_MESSAGE", elem.reason)}
            </Html>}
        </Item>)}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
OrderStateList.propTypes = {
    states : PropTypes.array.isRequired,
};

export default OrderStateList;
