import React                from "react";
import Store                from "Dashboard/Core/Store";

// Dashboard
import BarIcon              from "Dashboard/Components/Core/BarIcon";



/**
 * The Supervisor Menu
 * @returns {React.ReactElement}
 */
function SupervisorMenu() {
    const { closeMenu } = Store.useAction("core");
    const { isSupervisor } = Store.useState("auth");


    // Do the Render
    if (!isSupervisor) {
        return <React.Fragment />;
    }
    return <>
        <BarIcon
            variant="accent"
            url="DASHBOARD"
            icon="home"
            message="DASHBOARD_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            variant="accent"
            url="ORDERS"
            icon="order"
            message="ORDERS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
    </>;
}

export default SupervisorMenu;
