import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import { DashboardCatalog } from "Utils/API";



// The initial State
const initialState = {
    loading     : true,
    error       : false,
    list        : [],
    credentials : [],
    hasFilters  : false,
    filters     : {},
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "DASHBOARD_CATALOG_LOADING" });
    },

    /**
     * Fetches the Catalog Dashboard
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async fetchList(dispatch, params) {
        const { hasFilters, filters } = Commons.parseDashboardFilters(params);
        const data = await DashboardCatalog.getAll(filters);
        data.hasFilters = hasFilters;
        data.filters    = filters;
        dispatch({ type : "DASHBOARD_CATALOG_LIST", data });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "DASHBOARD_CATALOG_LIST")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "DASHBOARD_CATALOG_LOADING":
        return {
            ...state,
            loading     : true,
        };

    case "DASHBOARD_CATALOG_LIST":
        return {
            ...state,
            loading     : false,
            error       : false,
            list        : action.data.list,
            credentials : action.data.credentials,
            hasFilters  : action.data.hasFilters,
            filters     : action.data.filters,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
