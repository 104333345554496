import Store                    from "Dashboard/Core/Store";

// Core
import AuthStore                from "Stores/Core/AuthStore";

// Profile
import ProfileStore             from "Stores/App/Profile/ProfileStore";

// Dashboard
import DashboardFilterStore     from "Stores/App/Dashboard/DashboardFilterStore";
import DashboardDailyStore      from "Stores/App/Dashboard/DashboardDailyStore";
import DashboardCommercialStore from "Stores/App/Dashboard/DashboardCommercialStore";
import DashboardOperationsStore from "Stores/App/Dashboard/DashboardOperationsStore";
import DashboardCatalogStore    from "Stores/App/Dashboard/DashboardCatalogStore";
import DashboardMapStore        from "Stores/App/Dashboard/DashboardMapStore";
import DashboardProcessStore    from "Stores/App/Dashboard/DashboardProcessStore";

// Business
import PharmacyStore            from "Stores/App/Business/PharmacyStore";
import UserStore                from "Stores/App/Business/UserStore";
import ContactStore             from "Stores/App/Business/ContactStore";

// Subsidiary
import SubsidiaryStore          from "Stores/App/Subsidiary/SubsidiaryStore";
import SubsidiaryGatewayStore   from "Stores/App/Subsidiary/SubsidiaryGatewayStore";
import SubsidiaryLogisticStore  from "Stores/App/Subsidiary/SubsidiaryLogisticStore";
import SubsidiaryScheduleStore  from "Stores/App/Subsidiary/SubsidiaryScheduleStore";

// Order
import OrderStore               from "Stores/App/Order/OrderStore";
import ClientStore              from "Stores/App/Order/ClientStore";

// Catalog
import ProductStore             from "Stores/App/Catalog/ProductStore";
import MacroCategoryStore       from "Stores/App/Catalog/MacroCategoryStore";
import CategoryStore            from "Stores/App/Catalog/CategoryStore";
import SubCategoryStore         from "Stores/App/Catalog/SubCategoryStore";
import SpaceStore               from "Stores/App/Catalog/SpaceStore";
import BannerStore              from "Stores/App/Catalog/BannerStore";

// Medicine
import MedicineStore            from "Stores/App/Medicine/MedicineStore";
import LaboratoryStore          from "Stores/App/Medicine/LaboratoryStore";
import MonoDrugStore            from "Stores/App/Medicine/MonoDrugStore";
import DrugTypeStore            from "Stores/App/Medicine/DrugTypeStore";
import SaleTypeStore            from "Stores/App/Medicine/SaleTypeStore";
import HealthTypeStore          from "Stores/App/Medicine/HealthTypeStore";
import SizeTypeStore            from "Stores/App/Medicine/SizeTypeStore";
import ShapeTypeStore           from "Stores/App/Medicine/ShapeTypeStore";
import PotencyTypeStore         from "Stores/App/Medicine/PotencyTypeStore";
import UnitTypeStore            from "Stores/App/Medicine/UnitTypeStore";
import AdminRouteStore          from "Stores/App/Medicine/AdminRouteStore";
import PharmaActionStore        from "Stores/App/Medicine/PharmaActionStore";

// Change
import ChangeProductStore       from "Stores/App/Change/ChangeProductStore";
import ChangeCatalogStore       from "Stores/App/Change/ChangeCatalogStore";

// Logistic
import LogisticStore            from "Stores/App/Logistic/LogisticStore";
import RetirementTypeStore      from "Stores/App/Logistic/RetirementTypeStore";

// Transaction
import TransactionStore         from "Stores/App/Transaction/TransactionStore";
import TransactionTypeStore     from "Stores/App/Transaction/TransactionTypeStore";
import GatewayStore             from "Stores/App/Transaction/GatewayStore";
import PaymentTypeStore         from "Stores/App/Transaction/PaymentTypeStore";
import PaymentMethodStore       from "Stores/App/Transaction/PaymentMethodStore";
import CardTypeStore            from "Stores/App/Transaction/CardTypeStore";
import BankStore                from "Stores/App/Transaction/BankStore";
import CommissionStore          from "Stores/App/Transaction/CommissionStore";

// Setup
import AdminStore               from "Stores/App/Setup/AdminStore";
import ObjectiveStore           from "Stores/App/Setup/ObjectiveStore";
import HolidayStore             from "Stores/App/Setup/HolidayStore";
import StateStore               from "Stores/App/Setup/StateStore";
import StateGroupStore          from "Stores/App/Setup/StateGroupStore";
import CoverageTypeStore        from "Stores/App/Setup/CoverageTypeStore";
import RejectReasonStore        from "Stores/App/Setup/RejectReasonStore";
import CancelReasonStore        from "Stores/App/Setup/CancelReasonStore";
import TooltipStore             from "Stores/App/Setup/TooltipStore";
import MediaStore               from "Stores/App/Setup/MediaStore";
import DocumentStore            from "Stores/App/Setup/DocumentStore";

// Email
import EmailTemplateStore       from "Stores/App/Email/EmailTemplateStore";
import EmailQueueStore          from "Stores/App/Email/EmailQueueStore";
import EmailWhiteListStore      from "Stores/App/Email/EmailWhiteListStore";

// Log
import ProviderLogStore         from "Stores/App/Log/ProviderLogStore";
import ActionLogStore           from "Stores/App/Log/ActionLogStore";
import ErrorLogStore            from "Stores/App/Log/ErrorLogStore";



// The Combined Stores
export default Store.configureStore({
    auth                : AuthStore,

    // Profile
    profile             : ProfileStore,

    // Dashboard
    dashboardFilter     : DashboardFilterStore,
    dashboardDaily      : DashboardDailyStore,
    dashboardCommercial : DashboardCommercialStore,
    dashboardOperations : DashboardOperationsStore,
    dashboardCatalog    : DashboardCatalogStore,
    dashboardMap        : DashboardMapStore,
    dashboardProcess    : DashboardProcessStore,

    // Business
    pharmacy            : PharmacyStore,
    user                : UserStore,
    contact             : ContactStore,

    // Subsidiary
    subsidiary          : SubsidiaryStore,
    subsidiaryGateway   : SubsidiaryGatewayStore,
    subsidiaryLogistic  : SubsidiaryLogisticStore,
    subsidiarySchedule  : SubsidiaryScheduleStore,

    // Order
    order               : OrderStore,
    client              : ClientStore,

    // Catalog
    product             : ProductStore,
    macroCategory       : MacroCategoryStore,
    category            : CategoryStore,
    subCategory         : SubCategoryStore,
    space               : SpaceStore,
    banner              : BannerStore,

    // Medicine
    medicine            : MedicineStore,
    laboratory          : LaboratoryStore,
    monoDrug            : MonoDrugStore,
    drugType            : DrugTypeStore,
    saleType            : SaleTypeStore,
    healthType          : HealthTypeStore,
    sizeType            : SizeTypeStore,
    shapeType           : ShapeTypeStore,
    potencyType         : PotencyTypeStore,
    unitType            : UnitTypeStore,
    administrationRoute : AdminRouteStore,
    pharmaAction        : PharmaActionStore,

    // Change
    changeProduct       : ChangeProductStore,
    changeCatalog       : ChangeCatalogStore,

    // Logistic
    logistic            : LogisticStore,
    retirementType      : RetirementTypeStore,

    // Transaction
    transaction         : TransactionStore,
    transactionType     : TransactionTypeStore,
    gateway             : GatewayStore,
    paymentType         : PaymentTypeStore,
    paymentMethod       : PaymentMethodStore,
    cardType            : CardTypeStore,
    bank                : BankStore,
    commission          : CommissionStore,

    // Setup
    admin               : AdminStore,
    objective           : ObjectiveStore,
    holiday             : HolidayStore,
    state               : StateStore,
    stateGroup          : StateGroupStore,
    coverageType        : CoverageTypeStore,
    rejectReason        : RejectReasonStore,
    cancelReason        : CancelReasonStore,
    tooltip             : TooltipStore,
    media               : MediaStore,
    document            : DocumentStore,

    // Email
    emailTemplate       : EmailTemplateStore,
    emailQueue          : EmailQueueStore,
    emailWhiteList      : EmailWhiteListStore,

    // Log
    providerLog         : ProviderLogStore,
    actionLog           : ActionLogStore,
    errorLog            : ErrorLogStore,

});
