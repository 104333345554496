import React                from "react";
import Styled               from "styled-components";
import { Wrapper, Status }  from "@googlemaps/react-wrapper";
import Store                from "Dashboard/Core/Store";

// Components
import MapMarker            from "./MapMarker";
import MapInfoWindow        from "./MapInfoWindow";
import MapHeatmap           from "./MapHeatmap";

// Dashboard
import CircularLoader       from "Dashboard/Components/Loader/CircularLoader";



// Styled
const Map = Styled.div`
    box-sizing: border-box;
    width: 100%;
    height: calc(var(--main-height) - var(--header-height) - var(--main-padding) - var(--tabs-table) - 44px - var(--main-gap));
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);

    @media (max-width: 1200px) {
        height: calc(var(--main-height) - var(--header-height) - var(--main-padding) - var(--tabs-table) - 100px - var(--main-gap));
    }
`;



/**
 * The Map View
 * @returns {React.ReactElement}
 */
function MapView() {
    const { loading, list, clients, retirements, deliveries, options } = Store.useState("dashboardMap");


    // The Current State
    const [ mounted, setMounted ] = React.useState(false);
    const [ map,     setMap     ] = React.useState(null);
    const [ elemID,  setElemID  ] = React.useState(0);

    // Variables
    const center = { lat : -34.603722, lng : -58.481592 };


    // Load the Data
    React.useEffect(() => {
        return () => {
            setMap(null);
            setMounted(false);
        };
    }, []);

    // Create the Google Map
    const mapRef = React.useCallback((node) => {
        if (node && !mounted) {
            setMap(new window.google.maps.Map(node, {
                styles : [
                    {
                        "featureType" : "poi",
                        "stylers"     : [
                            { "visibility" : "off" },
                        ],
                    },
                    {
                        "featureType" : "transit",
                        "stylers"     : [
                            { "visibility" : "simplified" },
                        ],
                    },
                    {
                        "featureType" : "administrative.province",
                        "stylers"     : [
                            { "visibility" : "off" },
                        ],
                    },
                ],
                center,
                zoom              : 12,
                controlSize       : 24,
                mapTypeControl    : false,
                streetViewControl : false,
            }));
            setMounted(true);
        }
    }, []);

    // Center the Map
    React.useEffect(() => {
        if (map && list.length) {
            let   amount = 0;
            const bounds = new window.google.maps.LatLngBounds();
            for (const elem of list) {
                const distance = window.google.maps.geometry.spherical.computeDistanceBetween(center, elem.position);
                if (distance < 10000) {
                    bounds.extend(elem.position);
                    amount += 1;
                }
            }
            if (amount > 0) {
                map.setCenter(bounds.getCenter());
            }
        }
    }, [ map, list.length ]);

    // Handles the Marker click
    const handleClick = React.useCallback((newElemID) => {
        if (elemID === newElemID) {
            setElemID(0);
        } else {
            setElemID(newElemID);
        }
    }, [ elemID ]);


    // Renders the Component
    const render = (status) => {
        if (status === Status.LOADING) {
            return <CircularLoader />;
        }
        return <React.Fragment />;
    };


    // Do the Render
    if (loading) {
        return <CircularLoader />;
    }
    return <Wrapper
        apiKey={process.env.REACT_APP_GOOGLE_MAPS}
        libraries={[ "geometry", "visualization" ]}
        render={render}
    >
        <Map ref={mapRef} />
        {mounted && <>
            {list.map((elem) => <React.Fragment key={elem.id}>
                <MapMarker
                    map={map}
                    isHidden={!options.showSubsidiaries}
                    position={elem.position}
                    elemID={elem.id}
                    name={elem.name}
                    image={elem.image}
                    radius={options.subsidiaryRadius}
                    onClick={handleClick}
                />
                <MapInfoWindow
                    map={map}
                    isOpen={elemID === elem.id}
                    position={elem.position}
                    name={elem.name}
                    image={elem.image}
                    onClose={() => setElemID(0)}
                />
            </React.Fragment>)}

            <MapHeatmap
                map={map}
                list={clients}
                isHidden={!options.showClients}
                radius={options.clientRadius}
                purpleColor
            />
            <MapHeatmap
                map={map}
                list={retirements}
                isHidden={!options.showRetirements}
                radius={options.retirementRadius}
            />
            <MapHeatmap
                map={map}
                list={deliveries}
                isHidden={!options.showDeliveries}
                radius={options.deliveryRadius}
                blueColor
            />
        </>}
    </Wrapper>;
}

export default MapView;
