import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import DateTime             from "Dashboard/Utils/DateTime";
import Commons              from "Utils/Commons";

// Components
import OrderContent         from "./View/OrderContent";
import OrderCancel          from "./Dialogs/OrderCancel";
import ProcessView          from "Components/App/Dashboard/Process/ProcessView";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionOption         from "Dashboard/Components/Header/ActionOption";
import MenuLine             from "Dashboard/Components/Menu/MenuLine";
import PromptDialog         from "Dashboard/Components/Dialogs/PromptDialog";



/**
 * The Order Page
 * @returns {React.ReactElement}
 */
function OrderPage() {
    const { orderID } = Navigate.useParams();
    const parent      = Navigate.useParent(1);
    const navigate    = Navigate.useGoto();

    const { elem, products, states } = Store.useState("order");
    const { fetchElem, activateElem, retiredElem } = Store.useAction("order");

    const { isAnyAdmin } = Store.useState("auth");


    // The Current State
    const [ dialog,   setDialog   ] = React.useState("");
    const [ updating, setUpdating ] = React.useState(false);


    // Load the Data
    React.useEffect(() => {
        fetchElem(orderID);
    }, [ orderID ]);

    // Handle the Print
    const handlePrint = () => {
        Commons.printOrder(elem, products, states);
    };

    // Goes to the Chat
    const handleGotoChat = () => {
        window.open(`${process.env.REACT_APP_BOT_MAKER}${elem.chatID}`);
    };

    // Handles the Goto Subsidiary
    const handleGotoSubsidiary = () => {
        navigate("BUSINESS", "SUBSIDIARIES", elem.subsidiaryID);
    };

    // Handles the Goto Parent
    const handleGotoParent = () => {
        navigate("ORDERS", "RESERVES", elem.parentOrderID);
    };

    // Handles a Submit
    const handleSubmit = () => {
        fetchElem(orderID, false);
        setDialog("");
    };

    // Handles the Close
    const handleClose = (update) => {
        if (update) {
            handleSubmit();
        } else {
            setDialog("");
        }
    };

    // Handles the Activate
    const handleActivate = async (date, time) => {
        setUpdating(true);
        const result = await activateElem({ orderID, date, time });
        if (result.success) {
            await fetchElem(orderID, false);
            setDialog("");
        }
        setUpdating(false);
    };

    // Handles the Retired
    const handleRetired = async (date, time) => {
        setUpdating(true);
        const result = await retiredElem({ orderID, date, time });
        if (result.success) {
            await fetchElem(orderID, false);
            setDialog("");
        }
        setUpdating(false);
    };


    // Do the Render
    return <Main>
        <Header
            message={elem.name || "ORDERS_SINGULAR"}
            icon="order"
            href={parent}
        >
            <ActionList>
                <ActionItem
                    icon="print"
                    message="GENERAL_PRINT"
                    onClick={handlePrint}
                />
                <ActionItem
                    isHidden={!isAnyAdmin}
                    icon="more"
                    message="GENERAL_ACTIONS"
                >
                    <ActionOption
                        isHidden={!elem.chatID}
                        icon="view"
                        message="ORDERS_GOTO_CHAT"
                        onClick={handleGotoChat}
                    />
                    <ActionOption
                        icon="subsidiary"
                        message="ORDERS_GOTO_SUBSIDIARY"
                        onClick={handleGotoSubsidiary}
                    />
                    <ActionOption
                        isHidden={!elem.parentOrderID}
                        icon="order"
                        message="ORDERS_GOTO_PARENT"
                        onClick={handleGotoParent}
                    />

                    <MenuLine />
                    <ActionOption
                        icon="edit"
                        message="ORDERS_MANAGE_TITLE"
                        onClick={() => setDialog("manage")}
                    />
                    <ActionOption
                        isHidden={!elem.canForceActivate}
                        icon="check"
                        message="ORDERS_ACTIVATE_TITLE"
                        onClick={() => setDialog("activate")}
                    />
                    <ActionOption
                        isHidden={!elem.canForceRetired}
                        icon="check"
                        message="ORDERS_RETIRED_TITLE"
                        onClick={() => setDialog("retired")}
                    />
                    <ActionOption
                        isHidden={!elem.canForceCancel}
                        icon="cancel"
                        message="ORDERS_CANCEL_TITLE"
                        onClick={() => setDialog("cancel")}
                    />
                </ActionItem>
            </ActionList>
        </Header>

        <OrderContent />

        <ProcessView
            open={dialog === "manage"}
            elemID={elem.id}
            onSubmit={handleSubmit}
            onClose={handleClose}
        />

        <PromptDialog
            open={dialog === "activate"}
            icon="check"
            title="ORDERS_ACTIVATE_TITLE"
            message="ORDERS_ACTIVATE_TEXT"
            inputType="date"
            inputLabel="GENERAL_DATE"
            initialValue={DateTime.formatDate(elem.canceledTime, "dashesReverse")}
            secInputType="time"
            secInputLabel="GENERAL_HOUR"
            secInitialValue={DateTime.formatDate(elem.canceledTime, "time")}
            onSubmit={handleActivate}
            onClose={() => setDialog("")}
            isLoading={updating}
        />
        <PromptDialog
            open={dialog === "retired"}
            icon="check"
            title="DASHBOARD_PROCESS_RETIRED_TITLE"
            message="DASHBOARD_PROCESS_RETIRED_TEXT"
            inputType="date"
            inputLabel="GENERAL_DATE"
            initialValue={DateTime.formatDate(elem.canceledTime, "dashesReverse")}
            secInputType="time"
            secInputLabel="GENERAL_HOUR"
            secInitialValue={DateTime.formatDate(elem.canceledTime, "time")}
            onSubmit={handleRetired}
            onClose={() => setDialog("")}
            isLoading={updating}
        />
        <OrderCancel
            open={dialog === "cancel"}
            elemID={elem.id}
            onSubmit={handleSubmit}
            onClose={() => setDialog("")}
        />
    </Main>;
}

export default OrderPage;
