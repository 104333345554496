import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Product Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProductFilter(props) {
    const { open, filters, onClose, onSubmit } = props;

    const {
        macroCategories, categories, categoriesPerMacro,
        subCategories, subCategoriesPerMacro, subCategoriesPerCategory,
        spaces, laboratories, saleTypes, pharmaActions,
    } = Store.useState("product");


    // The Initial Data
    const initialData = {
        name            : "",
        benvidaID       : [],
        alfabetaID      : [],
        monoDrug        : "",
        barCode         : "",
        macroCategoryID : [],
        categoryID      : [],
        subCategoryID   : [],
        spaceID         : [],
        laboratoryID    : [],
        saleTypeID      : [],
        pharmaActionID  : [],
        reqRecipe       : [],
        showInactive    : 0,
    };

    // The Filter State
    const {
        loading, data, handleChange, handleSubmit,
    } = useFilter("product", open, initialData, filters, onSubmit);

    // Returns the selects based on the parents
    const getCategories    = Utils.useSelectList(loading, data.macroCategoryID, categories, categoriesPerMacro);
    const getSubCategories = Utils.useSubSelectList(loading, data.macroCategoryID, data.categoryID, subCategories, subCategoriesPerMacro, subCategoriesPerCategory);


    // Do the Render
    return <FilterDialog
        open={open}
        title="PRODUCTS_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns>
            <InputField
                className="columns-double"
                name="name"
                label="GENERAL_NAME"
                value={data.name}
                onChange={handleChange}
            />

            <InputField
                type="list"
                inputType="number"
                name="benvidaID"
                label="GENERAL_CODE_BENVIDA"
                value={data.benvidaID}
                onChange={handleChange}
            />
            <InputField
                type="list"
                inputType="number"
                name="alfabetaID"
                label="GENERAL_CODE_ALFABETA"
                value={data.alfabetaID}
                onChange={handleChange}
            />

            <InputField
                type="number"
                name="barCode"
                label="GENERAL_BAR_CODE"
                value={data.barCode}
                onChange={handleChange}
            />
            <InputField
                name="monoDrug"
                label="MONO_DRUGS_SINGULAR"
                value={data.monoDrug}
                onChange={handleChange}
            />
        </Columns>

        <InputField
            type="chooser"
            name="macroCategoryID"
            label="MACRO_CATEGORIES_NAME"
            options={macroCategories}
            value={data.macroCategoryID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="categoryID"
            label="CATEGORIES_NAME"
            options={getCategories()}
            value={data.categoryID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="subCategoryID"
            label="SUB_CATEGORIES_NAME"
            options={getSubCategories()}
            value={data.subCategoryID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="spaceID"
            label="SPACES_NAME"
            options={spaces}
            value={data.spaceID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="laboratoryID"
            label="LABORATORIES_NAME"
            options={laboratories}
            value={data.laboratoryID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="saleTypeID"
            label="SALE_TYPES_NAME"
            options={saleTypes}
            value={data.saleTypeID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="pharmaActionID"
            label="PHARMA_ACTIONS_NAME"
            options={pharmaActions}
            value={data.pharmaActionID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="reqRecipe"
            label="GENERAL_REQ_RECIPE"
            options="SELECT_REQ_RECIPES"
            value={data.reqRecipe}
            onChange={handleChange}
        />
        <InputField
            type="toggle"
            name="showInactive"
            label="GENERAL_SHOW_INACTIVE"
            value={data.showInactive}
            onChange={handleChange}
            withBorder
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProductFilter.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    filters  : PropTypes.object,
};

export default ProductFilter;
