import React                from "react";
import PropTypes            from "prop-types";
import { Line }             from "react-chartjs-2";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Components
import DashboardTitle       from "../../Utils/Components/DashboardTitle";



// Styles
const Container = Styled.section`
    flex-grow: 2;
    box-sizing: border-box;
    padding: var(--main-gap);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
`;

const Chart = Styled.div`
    height: calc(440px - 24px - 2 * var(--main-gap));
`;



/**
 * The Commercial Chart Line
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CommercialChartLine(props) {
    const { title, data : {
        labels, totalOrders, effectiveOrders,
    }, isDaily } = props;

    const { holidays } = Store.useState("dashboardCommercial");


    // Creates a Date and Text from the Label
    const crateDate = (label) => {
        const [ day, month, year ] = String(label).split("-");
        const date     = DateTime.create().changeYear(Number(year), Number(month), Number(day));
        const yearText = Number(year) !== DateTime.create().year ? ` ${year}` : "";
        const text     = `${date.day} ${date.getMonthName(3)}${yearText}`;
        return { date, text };
    };


    // Do the Render
    return <Container>
        <DashboardTitle message={title} />
        <Chart>
            <Line
                options={{
                    maintainAspectRatio : false,
                    interaction : {
                        mode      : "index",
                        intersect : false,
                    },
                    scales : {
                        x : Commons.getDateScale(holidays, isDaily),
                        y : Commons.getScaleOptions(false, false, false),
                    },
                    plugins : {
                        legend  : Commons.getLegendOptions("top"),
                        tooltip : Commons.getTooltipOptions(false, {
                            title(context) {
                                if (!isDaily) {
                                    return undefined;
                                }

                                const { date, text } = crateDate(context[0].label);
                                const holiday = Utils.getValue(holidays, "day", date.day, "name");
                                if (holiday) {
                                    return `${date.getDayName()}, ${text} (${holiday})`;
                                }
                                return `${date.getDayName()}, ${text}`;
                            },
                        }),
                    },
                }}
                data={{
                    labels   : labels,
                    datasets : [
                        {
                            // @ts-ignore
                            order            : 0,
                            yAxisID          : "y",
                            label            : NLS.get("DASHBOARD_EFFECTIVE_ORDERS"),
                            data             : effectiveOrders,
                            borderColor      : "rgb(181, 158, 236)",
                            backgroundColor  : "rgb(181, 158, 236)",
                            pointBorderWidth : 4,
                            tension          : 0.1,
                        },
                        {
                            // @ts-ignore
                            order            : 1,
                            yAxisID          : "y",
                            label            : NLS.get("DASHBOARD_TOTAL_ORDERS"),
                            data             : totalOrders,
                            borderColor      : "rgb(255, 134, 128)",
                            backgroundColor  : "rgb(255, 134, 128)",
                            pointBorderWidth : 4,
                            tension          : 0.1,
                        },
                    ],
                }}
            />
        </Chart>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CommercialChartLine.propTypes = {
    title   : PropTypes.string.isRequired,
    data    : PropTypes.object.isRequired,
    isDaily : PropTypes.bool,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
CommercialChartLine.defaultProps = {
    isDaily : false,
};

export default CommercialChartLine;
