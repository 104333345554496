import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Components
import ViewSection          from "./ViewSection";



// Styles
const Content = Styled.div.attrs(({ amount }) => ({ amount }))`
    display: grid;
    grid-template-columns: repeat(${(props) => props.amount}, 1fr);
    gap: var(--main-gap);

    .columns-double {
        grid-column-end: span 2;
    }

    @media (max-width: 1250px) {
        ${(props) => (Number(props.amount) === 3 || Number(props.amount) === 4) && `
            grid-template-columns: repeat(2, 1fr);
        `}
    }

    @media (max-width: 400px) {
        grid-template-columns: repeat(1, 1fr);

        .columns-double {
            grid-column-end: span 1;
        }
    }
`;



/**
 * The View Columns
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ViewColumns(props) {
    const { className, message, amount, children } = props;


    // Do the Render
    return <ViewSection className={className} message={message}>
        <Content amount={amount}>
            {children}
        </Content>
    </ViewSection>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ViewColumns.propTypes = {
    className : PropTypes.string,
    message   : PropTypes.string.isRequired,
    amount    : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
    children  : PropTypes.any,
};

export default ViewColumns;
