import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";

// The API
import {
    SubsidiaryGateway,
} from "Utils/API";



// The initial State
const initialState = {
    loading                 : true,
    charging                : true,
    error                   : false,
    edition                 : 0,
    canCreate               : false,
    canEdit                 : false,
    canFilter               : false,
    canImport               : false,
    canExport               : false,
    list                    : [],
    total                   : 0,
    content                 : {},
    gateways                : [],
    mpUrl                   : "",
    pharmacies              : [],
    subsidiaries            : [],
    subsidiariesPerPharmacy : {},
    filters                 : {},
    hasFilters              : false,
    sort                    : {
        orderBy  : "subsidiaryFullName",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "SUBSIDIARY_GATEWAY_LOADING" });
    },

    /**
     * Fetches the Subsidiary Gateways
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        if (type === "PHARMACY") {
            params.pharmacyID = elemID;
        } else if (type === "SUBSIDIARY") {
            params.subsidiaryID = elemID;
        }

        const data   = await SubsidiaryGateway.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "SUBSIDIARY_GATEWAY_LIST", data });
    },

    /**
     * Fetches all the Subsidiary Gateways
     * @param {Function} dispatch
     * @param {Number=}  subsidiaryID
     * @returns {Promise}
     */
    async fetchAll(dispatch, subsidiaryID) {
        dispatch({ type : "SUBSIDIARY_GATEWAY_CHARGING" });
        const data = await SubsidiaryGateway.getAllForSubsidiary({ subsidiaryID });
        dispatch({ type : "SUBSIDIARY_GATEWAY_ALL", data });
    },

    /**
     * Fetches the Subsidiary Filter data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchFilterData(dispatch) {
        const data = await SubsidiaryGateway.getFilterData();
        dispatch({ type : "SUBSIDIARY_GATEWAY_FILTER", data });
    },

    /**
     * Edits a Gateway
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    async editElem(dispatch, data) {
        return await SubsidiaryGateway.edit(data);
    },

    /**
     * Edits all the Gateways
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    async editAll(dispatch, data) {
        return await SubsidiaryGateway.editAll(data);
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.key            = `${elem.subsidiaryID}-${elem.gatewayCode}`;
    elem.isActiveText   = Utils.toYesNo(elem.isActive);
    elem.expirationDate = DateTime.formatIf(elem.expirationTime, "dashes");
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "SUBSIDIARY_GATEWAY_LIST")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "SUBSIDIARY_GATEWAY_LOADING":
        return {
            ...state,
            loading                 : true,
        };

    case "SUBSIDIARY_GATEWAY_CHARGING":
        return {
            ...state,
            charging                : true,
        };

    case "SUBSIDIARY_GATEWAY_LIST":
        return {
            ...state,
            error                   : false,
            loading                 : false,
            canCreate               : action.data.canCreate,
            canEdit                 : action.data.canEdit,
            canFilter               : action.data.canFilter,
            canImport               : action.data.canImport,
            canExport               : action.data.canExport,
            list                    : Utils.parseList(action.data.list, parseElem),
            total                   : action.data.total,
            filters                 : action.data.filters,
            hasFilters              : !Utils.isEmpty(action.data.filters),
            sort                    : action.data.sort,
        };

    case "SUBSIDIARY_GATEWAY_ALL":
        return {
            ...state,
            error                   : false,
            charging                : false,
            edition                 : state.edition + 1,
            canEdit                 : action.data.canEdit,
            content                 : action.data.content,
            gateways                : action.data.gateways,
            mpUrl                   : action.data.mpUrl,
        };

    case "SUBSIDIARY_GATEWAY_FILTER":
        return {
            ...state,
            error                   : false,
            edition                 : state.edition + 1,
            gateways                : action.data.gateways,
            pharmacies              : action.data.pharmacies,
            subsidiaries            : action.data.subsidiaries,
            subsidiariesPerPharmacy : action.data.subsidiariesPerPharmacy,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
