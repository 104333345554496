import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import DashboardTotals      from "../../Utils/Components/DashboardTotals";
import DashboardTotal       from "../../Utils/Components/DashboardTotal";



/**
 * The Commercial Totals
 * @returns {React.ReactElement}
 */
function CommercialTotals() {
    const { isAnyAdmin } = Store.useState("auth");
    const { totals, objectives } = Store.useState("dashboardCommercial");


    // Do the Render
    return <DashboardTotals>
        <DashboardTotal
            message="DASHBOARD_GROSS_BILLING"
            value={totals.grossBilling}
            isPrice
        />
        <DashboardTotal
            message="DASHBOARD_NET_BILLING"
            value={totals.netBilling}
            isPrice
        />
        <DashboardTotal
            message="DASHBOARD_AVERAGE_TICKET"
            value={totals.averageTicket}
            isPrice
        />
        <DashboardTotal
            isHidden={!isAnyAdmin}
            message="DASHBOARD_GROSS_OBJECTIVE"
            value={objectives.grossTotal || 0}
            isPrice
        />
        <DashboardTotal
            isHidden={!isAnyAdmin}
            message="DASHBOARD_OBJECTIVE_DIFF"
            value={totals.grossBilling - (objectives.grossTotal || 0)}
            isPrice
            isDiff
        />

        <DashboardTotal
            isHidden={!isAnyAdmin}
            message="DASHBOARD_BENVIDA_FEE_CHARGED"
            value={totals.benvidaFeeCharged}
            isPrice
        />

        <DashboardTotal
            isHidden={!isAnyAdmin}
            message="DASHBOARD_PENDING_ORDERS"
            value={totals.pendingOrders}
        />
        <DashboardTotal
            isHidden={!isAnyAdmin}
            message="DASHBOARD_TOTAL_ORDERS"
            value={totals.totalOrders}
        />
        <DashboardTotal
            message="DASHBOARD_EFFECTIVE_ORDERS"
            value={totals.effectiveOrders}
        />

        <DashboardTotal
            isHidden={!isAnyAdmin}
            message="OBJECTIVES_SALES_TOTAL"
            value={objectives.salesTotal || 0}
        />
        <DashboardTotal
            isHidden={!isAnyAdmin}
            message="DASHBOARD_OBJECTIVE_DIFF"
            value={totals.effectiveOrders - (objectives.salesTotal || 0)}
            isDiff
        />

        <DashboardTotal
            isHidden={!isAnyAdmin}
            message="DASHBOARD_BENVIDA_FEE_TO_CHARGE"
            value={totals.benvidaFeeToCharge}
            isPrice
        />
    </DashboardTotals>;
}

export default CommercialTotals;
