import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Container = Styled.section`
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    gap: var(--main-gap);
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: var(--main-gap) calc(var(--main-gap) * 2);
`;

const Inbox = Styled(Icon)`
    display: block;
    padding: 0 24px;
    margin-top: -40px;
    font-size: 72px;
    border-radius: calc(2 * var(--border-radius));
    background-color: var(--lighter-gray);
    color: var(--darkest-gray);
    opacity: 0.8;
`;

const Title = Styled.h4`
    margin: 0;
    font-size: 16px;
    font-weight: normal;
`;



/**
 * The Chat Empty
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChatEmpty(props) {
    const { canChat } = props;


    // Variables
    const message = React.useMemo(() => {
        let text = "DASHBOARD_PROCESS_NONE_MESSAGES";
        if (canChat) {
            text = "DASHBOARD_PROCESS_START_CHAT";
        }
        return text;
    }, [ canChat ]);


    // Do the Render
    return <Container>
        <Inbox icon="inbox" />
        <Title>{NLS.get(message)}</Title>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChatEmpty.propTypes = {
    canChat : PropTypes.bool,
};

export default ChatEmpty;
