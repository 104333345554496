import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// The API
import {
    SubsidiaryLogistic,
} from "Utils/API";



// The initial State
const initialState = {
    charging  : true,
    error     : false,
    canEdit   : false,
    edition   : 0,
    list      : {},
    logistics : [],
    saleTypes : [],
};



// The Actions
const actions = {
    /**
     * Fetches the Logistics
     * @param {Function} dispatch
     * @param {Number=}  subsidiaryID
     * @returns {Promise}
     */
    async fetchList(dispatch, subsidiaryID = 0) {
        dispatch({ type : "SUBSIDIARY_LOGISTIC_CHARGING" });
        const data = await SubsidiaryLogistic.getAll({ subsidiaryID });
        dispatch({ type : "SUBSIDIARY_LOGISTIC_LIST", data });
    },

    /**
     * Edits all the Logistics
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    async editAll(dispatch, data) {
        return await SubsidiaryLogistic.editAll(data);
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "SUBSIDIARY_LOGISTIC_LIST")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "SUBSIDIARY_LOGISTIC_CHARGING":
        return {
            ...state,
            charging  : true,
        };

    case "SUBSIDIARY_LOGISTIC_LIST":
        return {
            ...state,
            error     : false,
            charging  : false,
            edition   : state.edition + 1,
            canEdit   : action.data.canEdit,
            list      : action.data.list,
            logistics : action.data.logistics,
            saleTypes : action.data.saleTypes,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
