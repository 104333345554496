import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import ContactFilter        from "./ContactFilter";
import ContactView          from "./ContactView";
import ContactEdit          from "./ContactEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Contact List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ContactList(props) {
    const { type } = props;
    const { fetch, load, loadFilter } = useList("contact", type);

    const { pharmacyID } = Navigate.useParams();

    const data = Store.useState("contact");
    const { canEdit, list, total, filters, sort, loading } = data;

    const { deleteElem } = Store.useAction("contact");


    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteElem(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Variables
    const elemName      = Utils.getValue(list, "contactID", elemID, "name");
    const forSubsidiary = type === "SUBSIDIARY";


    // Do the Render
    return <Main>
        <Header message="CONTACTS_NAME" icon="contact">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="CONTACTS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="fullName"        message="GENERAL_NAME"        isTitle />
                    <TableHeader field="email"           message="GENERAL_EMAIL"       />
                    <TableHeader field="pharmacies"      message="PHARMACIES_SINGULAR" noSorting isHidden={!forSubsidiary} />
                    <TableHeader field="subsidiaries"    message="SUBSIDIARIES_NAME"   noSorting />
                    <TableHeader field="sendOrderEmails" message="GENERAL_SEND_EMAILS" align="center" maxWidth="100" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.contactID} elemID={elem.contactID}>
                        <TableCell message={elem.fullName}            />
                        <TableCell message={elem.email}               />
                        <TableCell message={elem.pharmaciesText}      />
                        <TableCell message={elem.subsidiariesText}    />
                        <TableCell message={elem.sendOrderEmailsText} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="VIEW"   message="CONTACTS_VIEW_TITLE"   isHidden={canEdit} />
                    <TableAction action="EDIT"   message="CONTACTS_EDIT_TITLE"   />
                    <TableAction action="DELETE" message="CONTACTS_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <ContactFilter
            open={action.isFilter}
            filters={filters}
            pharmacyID={pharmacyID}
            forSubsidiary={forSubsidiary}
            onSubmit={handleFilter}
            onClose={endAction}
        />
        <ContactView
            open={!canEdit && action.isView}
            elemID={elemID}
            onClose={endAction}
        />
        <ContactEdit
            open={canEdit && action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="CONTACTS_DELETE_TITLE"
            message="CONTACTS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ContactList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default ContactList;
