import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { DashboardFilter }  from "Utils/API";



// The initial State
const initialState = {
    loading                  : true,
    error                    : false,
    edition                  : 0,
    stateGroups              : [],
    stateTypes               : [],
    stateTypesPerGroup       : {},
    retirementTypes          : [],
    pharmacies               : [],
    subsidiaries             : [],
    subsidiariesPerPharmacy  : {},
    macroCategories          : [],
    categories               : [],
    categoriesPerMacro       : {},
    subCategories            : [],
    subCategoriesPerMacro    : {},
    subCategoriesPerCategory : {},
};



// The Actions
const actions = {
    /**
     * Fetches the Dashboard Filter data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchFilterData(dispatch) {
        const data = await DashboardFilter.getAll();
        dispatch({ type : "DASHBOARD_FILTER", data });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "DASHBOARD_FILTER")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "DASHBOARD_FILTER":
        return {
            ...state,
            error                    : false,
            edition                  : state.edition + 1,
            stateGroups              : action.data.stateGroups,
            stateTypes               : action.data.stateTypes,
            stateTypesPerGroup       : action.data.stateTypesPerGroup,
            retirementTypes          : action.data.retirementTypes,
            pharmacies               : action.data.pharmacies,
            subsidiaries             : action.data.subsidiaries,
            subsidiariesPerPharmacy  : action.data.subsidiariesPerPharmacy,
            macroCategories          : action.data.macroCategories,
            categories               : action.data.categories,
            categoriesPerMacro       : action.data.categoriesPerMacro,
            subCategories            : action.data.subCategories,
            subCategoriesPerMacro    : action.data.subCategoriesPerMacro,
            subCategoriesPerCategory : action.data.subCategoriesPerCategory,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
