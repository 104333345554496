import React                from "react";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";

// Components
import ProcessFile          from "../ProcessFile";
import ProcessSection       from "../Utils/ProcessSection";

// Dashboard
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Container = Styled(ProcessSection)`
    grid-area: request;
    min-height: 120px;
`;

const Products = Styled.div`
    margin-bottom: 16px;
    b {
        display: block;
        margin-bottom: 4px;
    }
`;



/**
 * The Process View Request
 * @returns {React.ReactElement}
 */
function ProcessRequest() {
    const { elem, recipeFiles } = Store.useState("dashboardProcess");

    // The Current State
    const [ recipeIndex, setRecipeIndex ] = React.useState(0);


    // Variables
    const recipe = recipeFiles[recipeIndex - 1] ?? {};


    // Do the Render
    return <>
        <Container message="ORDERS_CLIENT_REQUEST">
            <Products>
                <b>{NLS.get("ORDERS_PRODUCTS_NOTES")}</b>
                {elem.products || NLS.get("GENERAL_NONE")}
            </Products>

            {recipeFiles.map((elem, index) => <Button
                key={index}
                variant="outlined"
                icon="attachment"
                message={NLS.format("ORDERS_RECIPE_VIEW", index + 1)}
                onClick={() => setRecipeIndex(index + 1)}
                fullWidth
                isSmall
            />)}
        </Container>

        <ProcessFile
            open={recipeIndex !== 0}
            title={NLS.format("ORDERS_RECIPE_VIEW", String(recipeIndex))}
            isPDF={recipe.isPDF ?? false}
            isImage={recipe.isImage ?? false}
            fileUrl={recipe.url ?? ""}
            onClose={() => setRecipeIndex(0)}
        />
    </>;
}

export default ProcessRequest;
