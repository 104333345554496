import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Dashboard
import HyperLink            from "Dashboard/Components/Link/HyperLink";
import PDFViewer            from "Dashboard/Components/Media/PDFViewer";



// Styles
const ImageLink = Styled(HyperLink)`
    display: block;
    width: 100%;
    max-width: 300px;
`;

const Image = Styled.img`
    display: block;
    max-width: 100%;
    border-radius: var(--border-radius);
    overflow: hidden;
`;

const Video = Styled.video`
    display: block;
    width: 100%;
    max-height: 300px;
    max-width: 300px;
    border-radius: var(--border-radius);
    overflow: hidden;
`;

const Audio = Styled.audio`
    display: block;
    max-width: 100%;
`;

const PDF = Styled.div`
    width: 100%;
    max-width: 300px;
    max-height: 100px;
    border-radius: var(--border-radius);
    overflow: hidden;
    cursor: pointer;
`;

const Error = Styled.div`
    text-decoration: underline;
    transition: all 0.2s ease-in-out;
    &:hover {
        opacity: 0.5;
    }
`;

const FileLink = Styled(HyperLink)`
    display: block;
    text-decoration: underline;
    overflow: hidden;
    text-overflow: ellipsis;
`;



/**
 * The Chat Media
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChatMedia(props) {
    const { media : {
        file, fileUrl,
        hasImage, hasVideo, hasAudio, hasPDF, hasFile,
    } } = props;


    // Variables
    const showFile = hasFile  ?? (!hasImage && !hasVideo && !hasAudio && !hasPDF);
    const hasLink  = showFile && !!file;


    // Do the Render
    return <>
        {hasImage && <ImageLink
            variant="opacity"
            href={fileUrl}
            target="_blank"
        >
            <Image
                className="bubble-media"
                src={fileUrl}
            />
        </ImageLink>}

        {hasVideo && <Video
            className="bubble-media"
            src={fileUrl}
            controls
        />}

        {hasAudio && <Audio
            className="bubble-media"
            src={fileUrl}
            controls
        />}

        {hasPDF && <PDF
            className="bubble-media"
            onClick={() => window.open(fileUrl)}
        >
            <PDFViewer
                source={fileUrl}
                error={<Error>{file}</Error>}
                maxWidth={300}
            />
        </PDF>}

        {hasLink && <FileLink
            variant="opacity"
            href={fileUrl}
            target="_blank"
            preIcon="file"
            message={file}
        />}
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChatMedia.propTypes = {
    media : PropTypes.object.isRequired,
};

export default ChatMedia;
