import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// APIs
import {
    DashboardOperations,
} from "Utils/API";



// The initial State
const initialState = {
    loading                : true,
    error                  : false,
    totals                 : {
        pendingOrders   : 0,
        totalOrders     : 0,
        effectiveOrders : 0,
        pendingUnits    : 0,
        totalUnits      : 0,
        effectiveUnits  : 0,
    },
    stateTotals            : [],
    stateTimes             : [],
    rejectTotals           : [],
    cancelTotals           : [],
    createdHeatmap         : {},
    approvedHeatmap        : {},
    paidHeatmap            : {},
    retireHeatmap          : {},
    retiredHeatmap         : {},
    deliverHeatmap         : {},
    deliveredHeatmap       : {},
    rejectedHeatmap        : {},
    canceledHeatmap        : {},
    retirementAllTotals    : [],
    retirementTotals       : [],
    retirementSubsidiaries : [],
    retirementPharmacies   : [],
    hasFilters             : false,
    filters                : {},
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "DASHBOARD_OPERATIONS_LOADING" });
    },

    /**
     * Fetches the Operations Dashboard
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async fetchList(dispatch, params) {
        const { hasFilters, filters } = Commons.parseDashboardFilters(params);
        const data = await DashboardOperations.getAll(filters);
        data.hasFilters = hasFilters;
        data.filters    = filters;
        dispatch({ type : "DASHBOARD_OPERATIONS_LIST", data });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "DASHBOARD_OPERATIONS_LIST", "DASHBOARD_OPERATIONS_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "DASHBOARD_OPERATIONS_LOADING":
        return {
            ...state,
            loading                : true,
        };

    case "DASHBOARD_OPERATIONS_LIST":
        return {
            ...state,
            loading                : false,
            error                  : false,
            totals                 : action.data.totals,
            stateTotals            : action.data.stateTotals,
            stateTimes             : action.data.stateTimes,
            rejectTotals           : action.data.rejectTotals,
            cancelTotals           : action.data.cancelTotals,
            createdHeatmap         : action.data.createdHeatmap,
            approvedHeatmap        : action.data.approvedHeatmap,
            paidHeatmap            : action.data.paidHeatmap,
            retireHeatmap          : action.data.retireHeatmap,
            retiredHeatmap         : action.data.retiredHeatmap,
            deliverHeatmap         : action.data.deliverHeatmap,
            deliveredHeatmap       : action.data.deliveredHeatmap,
            rejectedHeatmap        : action.data.rejectedHeatmap,
            canceledHeatmap        : action.data.canceledHeatmap,
            retirementAllTotals    : action.data.retirementAllTotals,
            retirementTotals       : action.data.retirementTotals,
            retirementSubsidiaries : action.data.retirementSubsidiaries,
            retirementPharmacies   : action.data.retirementPharmacies,
            hasFilters             : action.data.hasFilters,
            filters                : action.data.filters,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
