import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { Logistic }         from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canFilter : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    sort      : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "LOGISTIC_LOADING" });
    },

    /**
     * Fetches the Logistic List
     * @param {Function} dispatch
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, params = {}) {
        const data = await Logistic.getAll(params);
        data.sort = params;
        dispatch({ type : "LOGISTIC_LIST", data });
    },

    /**
     * Fetches a single Logistic
     * @param {Function} dispatch
     * @param {Number}   logisticCode
     * @returns {Promise}
     */
    async fetchElem(dispatch, logisticCode) {
        const data = await Logistic.getOne({ logisticCode });
        dispatch({ type : "LOGISTIC_ELEM", data });
    },

    /**
     * Edits/Creates a Logistic
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return Logistic.edit(data);
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.isActiveText = Utils.toYesNo(elem.isActive);
    elem.feeValueText = elem.isFixed ? `$ ${elem.feeValue}` : `${elem.feeValue}%`;
    elem.minKMText    = `${elem.minKM} km`;
    elem.maxKMText    = `${elem.maxKM} km`;
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "LOGISTIC_LIST", "LOGISTIC_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "LOGISTIC_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "LOGISTIC_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canFilter : action.data.canFilter,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
            sort      : action.data.sort,
        };

    case "LOGISTIC_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : action.data.elem,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
