import React                from "react";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";

// Components
import ProcessSection       from "../Utils/ProcessSection";

// Dashboard
import HyperLink            from "Dashboard/Components/Link/HyperLink";



// Styles
const Container = Styled(ProcessSection)`
    grid-area: client;
`;

const Text = Styled.p`
    margin: 0;
    font-size: 14px;

    b {
        margin-right: 4px;
    }
`;



/**
 * The Process View Client
 * @returns {React.ReactElement}
 */
function ProcessClient() {
    const { isAnyAdmin } = Store.useState("auth");
    const { elem } = Store.useState("dashboardProcess");


    // Do the Render
    return <Container message="ORDERS_CLIENT_INFO">
        <Text>
            <b>{NLS.get("CLIENTS_DNI")}:</b>
            {elem.clientDni}
        </Text>

        {isAnyAdmin && elem.clientEmail && <Text>
            <b>{NLS.get("GENERAL_EMAIL")}:</b>
            <HyperLink
                message={elem.clientEmail}
                target="_blank"
                isEmail
            />
        </Text>}

        {isAnyAdmin && elem.clientPhone && <Text>
            <b>{NLS.get("GENERAL_CELLPHONE")}:</b>
            <HyperLink
                message={elem.clientPhone}
                target="_blank"
                isWhatsApp
            />
        </Text>}

        <Text>
            <b>{NLS.get("GENERAL_LOCATION")}:</b>
            {elem.clientLocation}
        </Text>
    </Container>;
}

export default ProcessClient;
