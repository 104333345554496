import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import { Medicine }         from "Utils/API";



// The initial State
const initialState = {
    loading              : true,
    charging             : false,
    error                : false,
    edition              : 0,
    canCreate            : false,
    canEdit              : false,
    canFilter            : false,
    canImport            : false,
    canExport            : false,
    list                 : [],
    total                : 0,
    elem                 : {},
    drugs                : [],
    productID            : 0,
    laboratories         : [],
    saleTypes            : [],
    healthTypes          : [],
    sizeTypes            : [],
    shapeTypes           : [],
    potencyTypes         : [],
    administrationRoutes : [],
    filters              : {},
    hasFilters           : false,
    sort                 : {
        orderBy  : "name",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "MEDICINE_LOADING" });
    },

    /**
     * Fetches the Medicine List
     * @param {Function} dispatch
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        const data   = await Medicine.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "MEDICINE_LIST", data });
    },

    /**
     * Fetches a single Medicine
     * @param {Function} dispatch
     * @param {Number}   medicineID
     * @returns {Promise}
     */
    async fetchElem(dispatch, medicineID) {
        dispatch({ type : "MEDICINE_CHARGING" });
        const data = await Medicine.getOne({ medicineID });
        dispatch({ type : "MEDICINE_ELEM", data });
    },

    /**
     * Fetches the Medicine Edit data
     * @param {Function} dispatch
     * @param {Number=}  medicineID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, medicineID = 0) {
        const data = await Medicine.getEditData({ medicineID });
        dispatch({ type : "MEDICINE_EDIT", data });
    },

    /**
     * Fetches the Medicine Filter data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchFilterData(dispatch) {
        const data = await Medicine.getFilterData();
        dispatch({ type : "MEDICINE_FILTER", data });
    },

    /**
     * Edits/Creates a Medicine
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return Medicine.edit(data);
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "MEDICINE_LIST", "MEDICINE_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "MEDICINE_LOADING":
        return {
            ...state,
            loading              : true,
        };

    case "MEDICINE_CHARGING":
        return {
            ...state,
            charging             : true,
        };

    case "MEDICINE_LIST":
        return {
            ...state,
            loading              : false,
            error                : false,
            canCreate            : action.data.canCreate,
            canEdit              : action.data.canEdit,
            canFilter            : action.data.canFilter,
            canImport            : action.data.canImport,
            canExport            : action.data.canExport,
            list                 : Utils.parseList(action.data.list, Commons.parseMedicine),
            total                : action.data.total,
            filters              : action.data.filters,
            hasFilters           : !Utils.isEmpty(action.data.filters),
            sort                 : action.data.sort,
        };

    case "MEDICINE_ELEM":
        return {
            ...state,
            charging             : false,
            error                : false,
            edition              : state.edition + 1,
            canEdit              : action.data.canEdit,
            elem                 : Commons.parseMedicine(action.data.elem),
            drugs                : Utils.parseList(action.data.drugs, Commons.parseDrug),
            productID            : action.data.productID,
        };

    case "MEDICINE_EDIT":
        return {
            ...state,
            error                : false,
            edition              : state.edition + 1,
            elem                 : action.data.elem ? Commons.parseMedicine(action.data.elem) : state.elem,
            drugs                : action.data.drugs ? Utils.parseList(action.data.drugs, Commons.parseDrug) : state.drugs,
            saleTypes            : action.data.saleTypes,
            healthTypes          : action.data.healthTypes,
            sizeTypes            : action.data.sizeTypes,
            shapeTypes           : action.data.shapeTypes,
            potencyTypes         : action.data.potencyTypes,
            administrationRoutes : action.data.administrationRoutes,
        };

    case "MEDICINE_FILTER":
        return {
            ...state,
            error                : false,
            edition              : state.edition + 1,
            laboratories         : action.data.laboratories,
            saleTypes            : action.data.saleTypes,
            healthTypes          : action.data.healthTypes,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
