import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// The API
import {
    SubsidiarySchedule,
} from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canFilter : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    sort      : {
        orderBy  : "type",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "SUBSIDIARY_SCHEDULE_LOADING" });
    },

    /**
     * Fetches the Schedule List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "SUBSIDIARY") {
            params.subsidiaryID = elemID;
        }
        const data = await SubsidiarySchedule.getAll(params);
        data.sort  = params;
        dispatch({ type : "SUBSIDIARY_SCHEDULE_LIST", data });
    },

    /**
     * Fetches a single Schedule
     * @param {Function} dispatch
     * @param {Number}   scheduleID
     * @returns {Promise}
     */
    async fetchElem(dispatch, scheduleID) {
        const data = await SubsidiarySchedule.getOne({ scheduleID });
        dispatch({ type : "SUBSIDIARY_SCHEDULE_ELEM", data });
    },

    /**
     * Creates an Schedule
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    createElem(dispatch, data) {
        return SubsidiarySchedule.create(data);
    },

    /**
     * Edits the given Schedule
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return SubsidiarySchedule.edit(data);
    },

    /**
     * Deletes a Schedule
     * @param {Function} dispatch
     * @param {Number}   scheduleID
     * @returns {Promise}
     */
    deleteElem(dispatch, scheduleID) {
        return SubsidiarySchedule.delete({ scheduleID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.typeName = NLS.get("SELECT_SCHEDULE_TYPES", elem.type);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "SUBSIDIARY_SCHEDULE_LIST", "SUBSIDIARY_SCHEDULE_ELEM", "SUBSIDIARY_SCHEDULE_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "SUBSIDIARY_SCHEDULE_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "SUBSIDIARY_SCHEDULE_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canFilter : action.data.canFilter,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
            sort      : action.data.sort,
        };

    case "SUBSIDIARY_SCHEDULE_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : parseElem(action.data.elem),
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
