import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { Category }         from "Utils/API";



// The initial State
const initialState = {
    loading         : true,
    error           : false,
    edition         : 0,
    canCreate       : false,
    canEdit         : false,
    canFilter       : false,
    canImport       : false,
    canExport       : false,
    list            : [],
    total           : 0,
    elem            : {},
    macroCategories : [],
    filters         : {},
    hasFilters      : false,
    sort            : {
        orderBy  : "mainPosition",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "CATEGORY_LOADING" });
    },

    /**
     * Fetches the Category List
     * @param {Function} dispatch
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        const data   = await Category.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "CATEGORY_LIST", data });
    },

    /**
     * Fetches a single Category
     * @param {Function} dispatch
     * @param {Number}   categoryID
     * @returns {Promise}
     */
    async fetchElem(dispatch, categoryID) {
        const data = await Category.getOne({ categoryID });
        dispatch({ type : "CATEGORY_ELEM", data });
    },

    /**
     * Fetches the Category Edit data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchEditData(dispatch) {
        const data = await Category.getEditData();
        dispatch({ type : "CATEGORY_EDIT", data });
    },

    /**
     * Edits/Creates a Category
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return Category.edit(data);
    },

    /**
     * Deletes a Category
     * @param {Function} dispatch
     * @param {Number}   categoryID
     * @returns {Promise}
     */
    deleteElem(dispatch, categoryID) {
        return Category.delete({ categoryID });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "CATEGORY_LIST", "CATEGORY_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "CATEGORY_LOADING":
        return {
            ...state,
            loading         : true,
        };

    case "CATEGORY_LIST":
        return {
            ...state,
            loading         : false,
            error           : false,
            canCreate       : action.data.canCreate,
            canEdit         : action.data.canEdit,
            canFilter       : action.data.canFilter,
            canImport       : action.data.canImport,
            canExport       : action.data.canExport,
            list            : action.data.list,
            total           : action.data.total,
            filters         : action.data.filters,
            hasFilters      : !Utils.isEmpty(action.data.filters),
            sort            : action.data.sort,
        };

    case "CATEGORY_ELEM":
        return {
            ...state,
            error           : false,
            edition         : state.edition + 1,
            elem            : action.data.elem,
            macroCategories : action.data.macroCategories,
        };

    case "CATEGORY_EDIT":
        return {
            ...state,
            error           : false,
            edition         : state.edition + 1,
            macroCategories : action.data.macroCategories,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
