import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import useList              from "Dashboard/Hooks/List";

// Components
import StateEdit           from "./StateEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import ColorCircle          from "Dashboard/Components/Common/ColorCircle";



/**
 * The State List
 * @returns {React.ReactElement}
 */
function StateList() {
    const { fetch, load } = useList("state");

    const data = Store.useState("state");
    const { loading, canEdit, list, total, sort } = data;


    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };


    // Do the Render
    return <Main>
        <Header message="STATES_NAME" icon="state">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="STATES_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="name"           message="GENERAL_NAME"          isTitle isFlex smallSpace />
                    <TableHeader field="stateGroupName" message="STATE_GROUPS_SINGULAR" />
                    <TableHeader field="code"           message="GENERAL_CODE"          maxWidth="130" />
                    <TableHeader field="position"       message="GENERAL_POSITION"      maxWidth="80" align="center" hideMobile />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.stateID} elemID={elem.stateID}>
                        <TableCell>
                            <ColorCircle color={elem.color} />
                            {elem.name}
                        </TableCell>
                        <TableCell message={elem.stateGroupName} />
                        <TableCell message={elem.code}           />
                        <TableCell message={elem.position}       />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT" message="STATES_EDIT_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <StateEdit
            open={action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
    </Main>;
}

export default StateList;
