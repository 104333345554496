import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Components
import DashboardTitle       from "./DashboardTitle";



// Styles
const Container = Styled.div`
    box-sizing: border-box;
    height: var(--dashboard-list);
    padding: var(--main-gap);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
`;

const Chart = Styled.div`
    height: calc(var(--dashboard-list) - 32px - 2 * var(--main-gap));
    margin-top: 8px;
`;



/**
 * The Dashboard Report
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardReport(props) {
    const { message, children } = props;


    // Do the Render
    return <Container>
        <DashboardTitle message={message} />
        <Chart>{children}</Chart>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardReport.propTypes = {
    message  : PropTypes.string.isRequired,
    children : PropTypes.any,
};

export default DashboardReport;
