import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";



// Styles
const Container = Styled.ul`
    --commercial-columns: 6;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--main-gap);
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        flex: 1 0 calc(100% / var(--commercial-columns) - var(--main-gap) * (var(--commercial-columns) - 1));
    }

    @media (max-width: 800px) {
        --commercial-columns: 4;
    }

    @media (max-width: 600px) {
        --commercial-columns: 3;
    }
`;



/**
 * The Dashboard Totals
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardTotals(props) {
    const { children } = props;


    // Do the Render
    return <Container>
        {children}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardTotals.propTypes = {
    children : PropTypes.any,
};

export default DashboardTotals;
