import React                from "react";
import PropTypes            from "prop-types";



/**
 * The Map Marker
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MapMarker(props) {
    const { isHidden, map, elemID, position, name, image, radius, onClick } = props;

    // The Current State
    const [ marker,   setMarker   ] = React.useState(null);
    const [ circle,   setCircle   ] = React.useState(null);
    const [ listener, setListener ] = React.useState(false);


    // The Custom Marker
    class CustomMarker extends window.google.maps.OverlayView {
        constructor() {
            super();
            this.setMap(map);
        }

        // Called when adding the Marker
        onAdd() {
            this.div = document.createElement("div");
            this.div.className = "map-marker";
            this.div.title     = name;
            if (image) {
                this.div.style.backgroundImage = `url(${image})`;
            }

            const panes = this.getPanes();
            panes.overlayMouseTarget.appendChild(this.div);

            if (this.listener) {
                this.div.addEventListener("click", this.listener);
            }
        }

        // Draws the Marker
        draw() {
            const point = this.getProjection().fromLatLngToDivPixel(position);
            if (point) {
                this.div.style.left = `${point.x}px`;
                this.div.style.top  = `${point.y}px`;
            }
        }

        // Adds the Marker Listeners
        addEventListener(eventName, listener) {
            if (this.div && this.listener) {
                this.div.removeEventListener(eventName, this.listener);
            }
            this.listener = listener;
            if (this.div) {
                this.div.addEventListener(eventName, listener);
            }
            return true;
        }

        // Removes the Marker
        remove() {
            if (this.div) {
                if (this.listener) {
                    this.div.removeEventListener("click", this.listener);
                }
                this.div.parentNode.removeChild(this.div);
                this.div = null;
            }
        }
    }


    // Create the Marker
    React.useEffect(() => {
        if (map && !marker && !circle) {
            const newMarker = new CustomMarker();
            const newCircle = new window.google.maps.Circle({
                map,
                strokeColor   : "#4fb9ad",
                strokeOpacity : 0.5,
                strokeWeight  : 1,
                fillColor     : "#4fb9ad",
                fillOpacity   : 0.15,
                center        : position,
                radius        : radius,
            });
            const listener = newMarker.addEventListener("click", () => {
                onClick(elemID);
            });

            if (isHidden) {
                newMarker.setMap(null);
                newCircle.setMap(null);
            }

            setMarker(newMarker);
            setCircle(newCircle);
            setListener(listener);
        }

        // Remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
                circle.setMap(null);
            }
        };
    }, [ map, marker, circle ]);

    // Shows or Hides the Markers
    React.useEffect(() => {
        if (isHidden && marker) {
            marker.setMap(null);
            circle.setMap(null);
        } else if (!isHidden && marker && map) {
            marker.setMap(map);
            circle.setMap(map);
        }
    }, [ isHidden ]);

    // Update the Click listener
    React.useEffect(() => {
        if (marker && listener) {
            const clk = marker.addEventListener("click", () => {
                onClick(elemID);
            });
            setListener(clk);
        }
    }, [ marker, onClick ]);

    // Updates the Circle Radius
    React.useEffect(() => {
        if (circle) {
            circle.setRadius(Number(radius));
        }
    }, [ circle, radius ]);


    // Do the Render
    return <React.Fragment />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MapMarker.propTypes = {
    map      : PropTypes.object.isRequired,
    isHidden : PropTypes.bool.isRequired,
    elemID   : PropTypes.number.isRequired,
    position : PropTypes.object.isRequired,
    name     : PropTypes.string.isRequired,
    image    : PropTypes.string.isRequired,
    radius   : PropTypes.number.isRequired,
    onClick  : PropTypes.func.isRequired,
};

export default MapMarker;
