import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Dashboard
import Avatar               from "Dashboard/Components/Avatar/Avatar";
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Section = Styled.section`
    box-sizing: border-box;
    position: sticky;
    top: 0;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    width: 180px;
    padding: 24px;
    border-left: 1px solid var(--border-color-medium);

    @media screen and (max-width: 700px) {
        width: 100%;
        position: static;
        border-left: none;
        justify-content: center;
    }
`;

const Input = Styled.input`
    display: none;
`;

const AvatarButton = Styled(Button)`
    & {
        margin-top: 16px;
        text-align: center;
    }
`;



/**
 * The Settings Avatar
 * @returns {React.ReactElement}
 */
function SettingsAvatar() {
    const { elem } = Store.useState("profile");
    const { fetchElem, uploadElem } = Store.useAction("profile");


    // The References
    const inputRef = React.useRef(null);

    // The Current State
    const [ loading, setLoading ] = React.useState(false);


    // Handles the Click
    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    // Handles the Submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        const files = e.target.files;
        if (files.length === 1 && !loading) {
            setLoading(true);
            await uploadElem(files[0]);
            await fetchElem();
            setLoading(false);
        }
    };


    // Do the Render
    return <Section>
        <Avatar
            name={elem.credentialName || ""}
            email={elem.email}
            avatar={elem.avatar}
            size={120}
            withReload
        />
        <Input
            type="file"
            name="file"
            accept="image/*"
            id="settings-file"
            onChange={handleSubmit}
            ref={inputRef}
        />
        <label htmlFor="settings-file">
            <AvatarButton
                variant="outlined"
                isDisabled={loading}
                onClick={handleClick}
                message="ACCOUNT_CHANGE_AVATAR"
            />
        </label>
    </Section>;
}

export default SettingsAvatar;
