import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import ClientFilter         from "./ClientFilter";
import ClientView           from "./ClientView";
import ClientEdit           from "./ClientEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Client List
 * @returns {React.ReactElement}
 */
function ClientList() {
    const { fetch, load, loadFilter } = useList("client");

    const data = Store.useState("client");
    const { loading, canEdit, list, total, filters, sort } = data;

    const { deleteElem } = Store.useAction("client");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteElem(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "clientID", elemID, "fullName");

    return <Main>
        <Header message="CLIENTS_NAME" icon="client">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="CLIENTS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="clientID"  message="GENERAL_ID"    maxWidth="50" />
                    <TableHeader field="fullName"  message="GENERAL_NAME"  isTitle />
                    <TableHeader field="email"     message="GENERAL_EMAIL" />
                    <TableHeader field="phone"     message="GENERAL_PHONE" maxWidth="130" />
                    <TableHeader field="dni"       message="CLIENTS_DNI"   maxWidth="100" />
                    <TableHeader field="birthDate" message="CLIENTS_BIRTH" maxWidth="100" />
                    <TableHeader field="age"       message="CLIENTS_AGE"   maxWidth="60" align="center" noSorting />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.clientID} elemID={elem.clientID}>
                        <TableCell message={elem.clientID}  />
                        <TableCell message={elem.fullName}  />
                        <TableCell message={elem.email}     />
                        <TableCell message={elem.phone}     />
                        <TableCell message={elem.dni}       />
                        <TableCell message={elem.birthText} />
                        <TableCell message={elem.age}       />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="VIEW"   message="CLIENTS_VIEW_TITLE"   isHidden={canEdit} />
                    <TableAction action="EDIT"   message="CLIENTS_EDIT_TITLE"   />
                    <TableAction action="DELETE" message="CLIENTS_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <ClientFilter
            open={action.isFilter}
            filters={filters}
            onSubmit={handleFilter}
            onClose={startAction}
        />
        <ClientView
            open={action.isView}
            elemID={elemID}
            onClose={endAction}
        />
        <ClientEdit
            open={canEdit && action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="CLIENTS_DELETE_TITLE"
            message="CLIENTS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

export default ClientList;
